import first from "lodash/first";
import last from "lodash/last";
import { TripCategory } from "types";
import * as yup from "yup";
import { checkIfStopTimesAreInOrder } from "../../../../../globals/utils/helpers";

const errorTripSchema = (variant: "trip" | "returnTrip") =>
  yup.object({
    totalGroupSize: yup
      .string()
      .required("Please enter a total passenger count")
      .typeError("Please enter a total passenger count"),

    tripCategory: yup
      .string()
      .nullable()
      .typeError("Please select trip type")
      .test({
        test: function (val) {
          if (variant === "returnTrip") return true;
          return !!val;
        },
      }),

    totalDuration: yup
      .number()
      .nullable()
      .typeError("Please select duration")
      .test({
        name: "Validate duration",
        message: "Please select duration",
        test: function () {
          const { parent } = this;

          return (
            parent.tripCategory !== TripCategory.Hourly ||
            !!parent.totalDuration
          );
        },
      }),
    stops: yup.array().of(
      yup.object({
        dateTime: yup
          .string()
          .nullable()
          .typeError("Please enter start date time")
          .test({
            name: "Validate DateTime",
            message: "Please enter start date time",
            test: function (dateTime) {
              // this.path === 'dateTime[0]'
              const index = parseInt(this.path.split("[")[1].split("]")[0]);

              // // first stop dateTime (pick-up) is required
              // // subsequent stop dateTimes are not required
              return !!dateTime || index !== 0;
            },
          })
          .test({
            name: "Validate order of round trip date time",
            message:
              "Return date & time must be later than pick-up date & time",
            test: function () {
              if (variant === "trip") return true;

              // @ts-ignore
              const globalData = last(this.from).value;
              const tripPickUpStop = first(globalData.trip.stops);
              const returnTripPickUpStop = first(globalData.returnTrip.stops);

              const stopsInOrder = checkIfStopTimesAreInOrder([
                tripPickUpStop,
                returnTripPickUpStop,
              ]);

              return !stopsInOrder.includes(true);
            },
          }),
        location: yup
          .string()
          .nullable()
          .typeError("Please enter location and select one from the dropdown")
          .test({
            name: "Validate location",
            message: "Please enter location and select one from the dropdown",
            test: function () {
              const {
                parent: { location, airport },
              } = this;

              if (location || airport) return true;
            },
          }),
        airport: yup
          .object()
          .nullable()
          .typeError("Please enter airport location")
          .test({
            name: "Validate airport",
            message: "Please enter airport location",
            test: function () {
              const {
                parent: { airport, location },
              } = this;

              if (airport || location) return true;
            },
          }),
        skipFlightDetails: yup.boolean().nullable(),
        isLocationAirport: yup.boolean().nullable(),
        airline: yup
          .object()
          .nullable()
          .typeError("Please enter airline")
          .test({
            name: "Validate airline",
            message: "Please enter airline",
            test: function () {
              const {
                parent: { isLocationAirport, skipFlightDetails, airline },
              } = this;

              // If skipFlightDetails is true, no need to validate
              if (skipFlightDetails) return true;

              // If location is airport and skipFlightDetails is not checked, airline is required
              if (isLocationAirport && !skipFlightDetails) {
                return !!airline;
              }

              // For non-airport stops, no validation needed
              return true;
            },
          }),
        flightNumber: yup
          .string()
          .nullable()
          .typeError("Please enter flight number")
          .test({
            name: "Validate flight number",
            message: "Please enter flight number",
            test: function () {
              const {
                parent: { isLocationAirport, skipFlightDetails, flightNumber },
              } = this;

              // If skipFlightDetails is true, no need to validate
              if (skipFlightDetails) return true;

              // If location is airport and skipFlightDetails is not checked, flightNumber is required
              if (isLocationAirport && !skipFlightDetails) {
                return !!flightNumber;
              }

              // For non-airport stops, no validation needed
              return true;
            },
          }),
        trackedFlight: yup
          .object()
          .nullable()
          .typeError("Please select a valid flight")
          .test({
            name: "Validate tracked flight",
            message: "Please add a valid flight",
            test: function () {
              const {
                parent: { isLocationAirport, skipFlightDetails, trackedFlight },
              } = this;
              if (skipFlightDetails) {
                return true;
              }

              if (isLocationAirport && !skipFlightDetails) {
                return !!trackedFlight;
              }

              return true;
            },
          }),
      })
    ),
  });

export const infoPageErrorSchema = yup
  .object({
    orderType: yup.object().required("Please select an order type").nullable(),
    trip: errorTripSchema("trip"),
    returnTrip: errorTripSchema("returnTrip").nullable(),
  })
  .required();
