import React, { useState } from "react";
import map from "lodash/map";
import mapValues from "lodash/mapValues";

import { Typography, Box } from "@mui/material";

import MoovsTabs from "../../components/globals/MoovsTabs";
import { RequestStage } from "../../types";
import LoginLogoutBlock from "../../components/auth/LoginLogoutBlock/LoginLogoutBlock";
import { useOperator, useScreenSize } from "../../globals/hooks";
import ReservationsTab from "./components/ReservationsTab";
import QuotesTab from "./components/QuotesTab";
import TripsAsPassengerTab from "./components/TripsAsPassengerTab";
import ReservationDropdownFilter, {
  ReservationFilterStatusVariant,
  defaultFilterStatuses,
} from "./components/ReservationDropdownFilter";
import { useHistory, useLocation } from "react-router-dom";

enum TripType {
  TripAsPassenger = "TRIP_AS_PASSENGER",
  Trips = "TRIPS",
}

type ActiveTab = RequestStage.Reservation | RequestStage.Quote | TripType.Trips;

function OrdersPage() {
  const { isMobileView } = useScreenSize();
  const { operator } = useOperator();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  // state
  const [activeTab, setActiveTab] = useState<ActiveTab>(
    query.get("tab") as ActiveTab || RequestStage.Reservation
  );
  const [filterStatuses, setFilterStatuses] = useState<
    ReservationFilterStatusVariant[]
  >(defaultFilterStatuses);

  // derived state
  const showQuotes = operator?.settings?.customerPortalQuotesVisible;

  const tabConfig = {
    [RequestStage.Reservation]: {
      label: "RESERVATIONS",
      component: (
        <ReservationsTab
          filterStatuses={filterStatuses}
          setFilterStatuses={setFilterStatuses}
        />
      ),
    },
    ...(showQuotes && {
      [RequestStage.Quote]: { label: "QUOTES", component: <QuotesTab /> },
    }),
    [TripType.Trips]: {
      label: "TRIPS",
      component: <TripsAsPassengerTab />,
    },
  };

  const tabs = mapValues(tabConfig, "component");
  const tabValues = map(tabConfig, (config, value) => ({
    label: config.label,
    value,
  }));

  const handleTabChange = (tab: ActiveTab) => {
    setActiveTab(tab);
    history.push({
      pathname: pathname,
      search: `tab=${tab}`,
    });
  };

  return (
    <Box display="flex" justifyContent="center" flex="1">
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        maxWidth="1140px"
        px={isMobileView ? 2 : 0}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          my={2}
        >
          <Typography variant="h1" style={{ fontWeight: 600 }}>
            Orders
          </Typography>
          <LoginLogoutBlock />
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <MoovsTabs
            onChange={handleTabChange}
            activeTab={activeTab}
            tabValues={tabValues}
          />
          {!isMobileView && activeTab === RequestStage.Reservation ? (
            <Box minHeight="75px">
              <ReservationDropdownFilter
                filterStatuses={filterStatuses}
                setFilterStatuses={setFilterStatuses}
              />
            </Box>
          ) : (
            <Box minHeight="75px"></Box>
          )}
        </Box>
        {tabs[activeTab]}
      </Box>
    </Box>
  );
}

export default OrdersPage;
