/**
 * @file ATICreateWrapper.tsx
 *
 * Wrapper around ATIDialog to handle submitting and feeding in initial data, specifically for creating a request
 */
import React from "react";

import { CreateRequestReduxActionTypes } from "pages/new/context/reducer/types";
import { useCreateRequestContext } from "pages/new/context/useCreateRequestContext";
import { AdditionalTripInfoState } from "../form/schemaValidation";
import { TemporaryPassenger } from "types";
import ATIDialog from "./ATIDialog";
import first from "lodash/first";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";

type ATICreateWrapperProps = {
  onClose: () => void;
  open: boolean;
  trip: {
    id: string;
    totalGroupSize: number;
    note?: string;
    temporaryPassenger?: TemporaryPassenger;
    routes: {
      id: string;
      carryOnLuggage?: number;
      checkedLuggage?: number;
      oversizeLuggage?: number;
    }[];
  };
};

function ATICreateWrapper(props: ATICreateWrapperProps) {
  const { onClose, open, trip } = props;

  // hooks
  const [_, dispatch] = useCreateRequestContext();
  // event handlers
  const handleSubmit = (additionalTripInfoState: AdditionalTripInfoState) => {
    const {
      note,
      totalGroupSize,
      temporaryPassenger,
      route,
      selectedPassenger,
    } = additionalTripInfoState;

    const { formatted, international } =
      formatPhoneNumber(
        additionalTripInfoState.temporaryPassenger.phoneFields.mobilePhone,
        additionalTripInfoState.temporaryPassenger.phoneFields.phoneCountryCode,
        additionalTripInfoState.temporaryPassenger.phoneFields
          .phoneCountryDialCode
      ) || {};

    dispatch({
      type: CreateRequestReduxActionTypes.UpdateAdditionalTripInfo,
      payload: {
        totalGroupSize,
        note,
        id: trip.id,
        // merge phone fields back into temporaryPassenger to match graphql schema for TemporaryPassenger
        temporaryPassenger: {
          ...temporaryPassenger.phoneFields,
          name: temporaryPassenger.name,
          mobilePhone: formatted,
          mobilePhoneInternational: international,
        },
        routes: [
          {
            id: route.id,
            carryOnLuggage: route.carryOnLuggage,
            checkedLuggage: route.checkedLuggage,
            oversizeLuggage: route.oversizeLuggage,
          },
        ],
        selectedPassenger,
      },
    });

    onClose();
  };

  return (
    <ATIDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      open={open}
      isSubmitting={false}
      initialState={{
        ...trip,
        route: first(trip.routes),
        selectedPassenger: null,
      }}
    />
  );
}

export default ATICreateWrapper;
