import {
  ApolloError,
  ApolloQueryResult,
  FetchMoreQueryOptions,
  useQuery,
} from "@apollo/client";
import map from "lodash/map";

import { LOAD_TRIPS_AS_PASSENGER_QUERY } from "globals/graphql";

import {
  LoadTripsAsPassengerConnection,
  RequestTimeFrameEnum,
  Trip,
} from "types";

const PAGINATION_LIMIT = 25;

type UseTripsAsPassengerTuple = [
  Trip[],
  ApolloError,
  boolean,
  (options: FetchMoreQueryOptions<any, any>) => Promise<ApolloQueryResult<any>>,
  boolean,
  string,
  () => void
];

type Options = {
  timeFrame: RequestTimeFrameEnum;
  skip?: boolean;
};

function useLoadTripsAsPassenger(options: Options): UseTripsAsPassengerTuple {
  const { timeFrame, skip } = options;

  // queries
  const { data, error, loading, fetchMore, refetch } = useQuery<{
    loadTripsAsPassenger: LoadTripsAsPassengerConnection;
  }>(LOAD_TRIPS_AS_PASSENGER_QUERY, {
    skip,
    variables: {
      timeFrame,
      limit: PAGINATION_LIMIT,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const hasNextPage =
    data?.loadTripsAsPassenger?.pageInfo?.hasNextPage ?? false;
  const cursor = data?.loadTripsAsPassenger?.pageInfo?.endCursor ?? "";

  const trips = skip
    ? []
    : map(data?.loadTripsAsPassenger?.edges, ({ node }) => node);

  return [trips, error, loading, fetchMore, hasNextPage, cursor, refetch];
}

export { useLoadTripsAsPassenger };
