import React, { useEffect } from "react";
import { Control, UseFormWatch, UseFormSetValue } from "react-hook-form";

import { Box, Divider, Typography } from "@mui/material";

import { RHFMoovsIncrementer } from "components/react-hook-form/RHFMoovsIncrementer";
import { useCreateRequestContext } from "pages/new/context/useCreateRequestContext";
import { CreateRequestReduxActionTypes } from "pages/new/context/reducer/types";
import { CreateRequestState } from "pages/new/context/initialState";
import first from "lodash/first";
import last from "lodash/last";
import { useLuggage } from "globals/hooks";

type LuggageCountBlockProps = {
  control: Control<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  syncStateWithOutboundTrip: boolean; // If true, the form state and the redux state for the outbound trip will be synced
  forceDisplay?: boolean;
};

function LuggageCountBlock(props: LuggageCountBlockProps) {
  const isAirportTrip = (request: CreateRequestState) => {
    const firstStop = first(request.trip.stops);
    const lastStop = last(request.trip.stops);
    return (
      !!firstStop?.airport?.icaoCode ||
      firstStop?.pickUpGooglePlaceTypes?.includes("airport") ||
      !!lastStop?.airport?.icaoCode ||
      lastStop?.pickUpGooglePlaceTypes?.includes("airport")
    );
  };

  const {
    control,
    watch,
    setValue,
    syncStateWithOutboundTrip: syncState = false,
    forceDisplay = false,
  } = props;
  const [request, dispatch] = useCreateRequestContext();

  const carryOnLuggage = watch("route.carryOnLuggage") || 0;
  const checkedLuggage = watch("route.checkedLuggage") || 0;
  const oversizeLuggage = watch("route.oversizeLuggage") || 0;

  const { isLuggageEnabled } = useLuggage();
  const routeId = request.trip.routes?.[0]?.id;

  // Sync Redux state back to form state
  useEffect(() => {
    if (!syncState) {
      return;
    }
    if (request.trip.routes?.length) {
      const route = request.trip.routes[0];
      if (route.carryOnLuggage !== undefined)
        setValue("route.carryOnLuggage", route.carryOnLuggage);
      if (route.checkedLuggage !== undefined)
        setValue("route.checkedLuggage", route.checkedLuggage);
      if (route.oversizeLuggage !== undefined)
        setValue("route.oversizeLuggage", route.oversizeLuggage);
    }
  }, [request.trip.routes, setValue, syncState]);

  // Update luggage values in the react reducer state when they change in the react hook form state
  useEffect(() => {
    if (!syncState) {
      return;
    }
    if (!request.trip.routes?.length) {
      return;
    }

    dispatch({
      type: CreateRequestReduxActionTypes.UpdateAdditionalTripInfo,
      payload: {
        ...request.trip,
        routes: [
          {
            id: routeId,
            carryOnLuggage,
            checkedLuggage,
            oversizeLuggage,
          },
        ],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carryOnLuggage, checkedLuggage, oversizeLuggage, dispatch, routeId]);

  return (
    <>
      {isLuggageEnabled && (isAirportTrip(request) || forceDisplay) && (
        <Box display="flex" flexDirection="column" mt={1}>
          <Typography variant="h4">Luggage Count</Typography>
          {/* Carry On */}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2">Carry-On</Typography>
            <RHFMoovsIncrementer
              name="route.carryOnLuggage"
              control={control}
              limits={[MIN_GROUP_SIZE, MAX_GROUP_SIZE]}
              ButtonProps={{ variant: "text" }}
            />
          </Box>

          {/* Checked */}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2">Checked</Typography>
            <RHFMoovsIncrementer
              name="route.checkedLuggage"
              control={control}
              limits={[MIN_GROUP_SIZE, MAX_GROUP_SIZE]}
              ButtonProps={{ variant: "text" }}
            />
          </Box>

          {/* Oversize */}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2">Oversize</Typography>
            <RHFMoovsIncrementer
              name="route.oversizeLuggage"
              control={control}
              limits={[MIN_GROUP_SIZE, MAX_GROUP_SIZE]}
              ButtonProps={{ variant: "text" }}
            />
          </Box>

          <Divider sx={{ mb: 3, mt: 3 }} />
        </Box>
      )}
    </>
  );
}

export default LuggageCountBlock;

// constants
const MAX_GROUP_SIZE = 99;
const MIN_GROUP_SIZE = 1;
