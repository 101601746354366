import React from "react";

import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Trip } from "types";



function NumberOfPassengersColumn(props: GridRenderCellParams<Trip>) {
  const { row } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="100%"
    >
      <Typography variant="body2"  overflow="hidden">
        {row.totalGroupSize}
      </Typography>
    </Box>
  );
}

export default NumberOfPassengersColumn;
