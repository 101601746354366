import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useIsWhiteLabel } from "pages/new/hooks";
import { useCreateQuote } from "pages/new/hooks/useCreateQuote";
import { useCreateWhiteLabelQuote } from "pages/new/hooks/useCreateWhiteLabelQuote";
import CreateQuoteFormFields from "./components/CreateQuoteFormFields";
import {
  useAuthenticationChangeUpdate,
  useUnmountUpdate,
  useUpdateBookingContact,
} from "components/confirm/form/hooks";
import { useAnalytics, useAuth } from "globals/hooks";
import { getCreateQuoteFormDefaultValues } from "./form/defaultValues";
import {
  CreateQuoteFormState,
  createQuoteFormErrorSchema,
} from "./form/schemaValidation";
import { useCreateRequestContext } from "pages/new/context/useCreateRequestContext";
import { getIsNetflixLogin } from "utils/auth/isNetflixLogin";

function CreateQuoteForm() {
  // hooks
  const { createWhiteLabelQuote, isCreatingWhiteLabel } =
    useCreateWhiteLabelQuote();
  const { isWhiteLabel } = useIsWhiteLabel();
  const { authStage, loginData } = useAuth();
  const [request] = useCreateRequestContext();
  const { track } = useAnalytics();
  const isNetflixLogin = getIsNetflixLogin();

  // hooks continued
  const { handleSubmit, control, watch, setValue, getValues, setError } =
    useForm<CreateQuoteFormState>({
      defaultValues: getCreateQuoteFormDefaultValues(
        request.bookingContact,
        request.trip
      ),
      mode: "onSubmit",
      resolver: yupResolver(createQuoteFormErrorSchema),
      context: {
        isCreatingBookingContact:
          authStage !== "authenticated" ||
          (isNetflixLogin && !loginData.mobilePhone),
      },
      // prevents focusing input fields which will trigger tracking event
      shouldFocusError: false,
    });
  useUnmountUpdate({ watch });
  useUpdateBookingContact({ watch, getValues });
  useAuthenticationChangeUpdate({ getValues, setValue, isNetflixLogin });
  const { createQuote, isCreatingQuote } = useCreateQuote({ setError });

  // event handlers
  const handleCreateQuote = isWhiteLabel ? createWhiteLabelQuote : createQuote;

  const handleInvalidForm = () => {
    track("quoteRequest_submitAttempted");
  };

  return (
    <form
      id={FORM_ID}
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(handleCreateQuote, handleInvalidForm)(e);
      }}
    >
      <CreateQuoteFormFields
        control={control}
        watch={watch}
        isSubmitting={isCreatingQuote || isCreatingWhiteLabel}
        formId={FORM_ID}
        authStage={authStage}
        setValue={setValue}
        getValues={getValues}
      />
    </form>
  );
}

export default CreateQuoteForm;

// constants
const FORM_ID = "create-quote-form";
