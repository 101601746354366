import React, { useMemo, useRef } from "react";
import moment, { Moment } from "moment-timezone";
import size from "lodash/size";

import { Box, InputAdornment, TextField } from "@mui/material";
import StaticDatePicker, {
  StaticDatePickerProps,
} from "@mui/lab/StaticDatePicker";
import PickersDay from "@mui/lab/PickersDay";

import { moovsBlue, moovsBlueHover, white } from "design-system/colors";
import { CalendarIcon } from "design-system/icons";
import { useScreenSize } from "globals/hooks";

type MoovsDateTimePickerProps = {
  dateList: Moment[];
  setDateList: React.Dispatch<React.SetStateAction<any[]>>;
  errorMsg: string;
  dateLimit: number;
  hideCalendarIcon?: boolean;
  datePickerProps?: Omit<
    StaticDatePickerProps,
    "onChange" | "renderInput" | "value"
  >;
};

function MoovsMultiDatePicker(props: MoovsDateTimePickerProps) {
  const {
    dateList,
    setDateList,
    errorMsg,
    hideCalendarIcon,
    dateLimit,
    datePickerProps,
  } = props;

  // refs
  const textFieldRef = useRef(null);

  // hooks
  const { isMobileView } = useScreenSize();

  const datesInputDisplay = useMemo(() => {
    const formattedDates = dateList.map((date) => date.format("MM/DD/YYYY"));
    return formattedDates.join(", ");
  }, [dateList]);

  const findDate = (dates, date) => {
    const dateTime = date.startOf("day");
    return !!dates.find((d) => d.startOf("day").isSame(dateTime));
  };

  const findIndexDate = (dates, date) => {
    const dateTime = date.startOf("day");
    return dates.findIndex((d) => d.startOf("day").isSame(dateTime));
  };

  const renderPickerDay = (date, _, pickersDayProps) => {
    if (!size(dateList)) {
      return (
        <PickersDay
          {...pickersDayProps}
          sx={[{ "&: hover": { backgroundColor: moovsBlueHover } }]}
          selected={false}
          disabled={date.isBefore(moment().startOf("day"))}
        />
      );
    }

    const selected = findDate(dateList, date);


    return (
      <PickersDay
        {...pickersDayProps}
        selected={selected}
        sx={[{ "&: hover": { backgroundColor: moovsBlueHover } }]}
        // styling for selected day
        {...(selected && {
          style: {
            backgroundColor: moovsBlue,
            color: white,
          },
        })}
        // if selected dates at dateLimit and current day is not selected, disable the day
      disabled={date.isBefore(moment().startOf("day")) || (!(size(dateList) < dateLimit) &&
        !selected )}
      />
    );
  };

  return (
    <Box width="100%" height="100%" mt={3}>
      <TextField
        inputRef={textFieldRef}
        variant="outlined"
        fullWidth
        multiline
        label={"Select all dates to duplicate on"}
        value={datesInputDisplay}
        error={!!errorMsg}
        helperText={errorMsg}
        InputProps={
          !hideCalendarIcon && {
            endAdornment: (
              <InputAdornment position="end" sx={{ mr: 1 }}>
                <CalendarIcon />
              </InputAdornment>
            ),
          }
        }
        focused={false}
        onFocus={() => {
          if (textFieldRef) textFieldRef.current.blur();
        }}
        sx={{ my: 2 }}
      />

      <StaticDatePicker
        {...datePickerProps}
        displayStaticWrapperAs={isMobileView ? "mobile" : "desktop"}
        value={dateList}
        showToolbar={false}
        allowSameDateSelection={true}
        onChange={(newValue) => {
          //copying the dates array
          const datesCopy = [...dateList];
          const date = newValue;
          const index = findIndexDate(datesCopy, date);

          if (index >= 0) {
            datesCopy.splice(index, 1);
          } else {
            datesCopy.push(date);
          }

          setDateList(datesCopy);
        }}
        renderDay={renderPickerDay}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        inputFormat="MM/DD/YYYY"
      />
    </Box>
  );
}

export default MoovsMultiDatePicker;
