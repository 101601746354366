/**
 * @file ConfirmStep.tsx
 * Third step of create request stepper
 *
 * See OrderPage for the very similar looking page that is used on already created requests.
 */
import React, { useEffect } from "react";
import first from "lodash/first";
import compact from "lodash/compact";
import { useHistory } from "react-router-dom";

import { Box, Button } from "@mui/material";

import {
  useAnalytics,
  useAuth,
  useCurrentUser,
  useGoogleTracking,
  usePageViewedTracking,
  useScreenSize,
  useScrollToTop,
} from "globals/hooks";
import { useCreateRequestContext } from "pages/new/context/useCreateRequestContext";
import { RequestStage } from "types";
import CreateQuoteForm from "./components/CreateQuoteForm";
import ContactUs from "components/confirm/ContactUs";
import OrderDetailsHeader from "components/confirm/OrderDetailsHeader";
import TripDetailsCreateBlock from "components/confirm/TripDetailsBlock/TripDetailsCreateBlock";
import CreateReservationForm from "./components/CreateReservationForm";
import { useIsPricelessBooking, useIsWhiteLabel } from "pages/new/hooks";
import StatusStepper from "../components/StatusStepper";
import { PricelessBookingProvider } from "./context";

function ConfirmStep() {
  // hooks
  useScrollToTop({ scrollOnMount: true });
  const history = useHistory();
  const [request] = useCreateRequestContext();
  const { isMobileView, isTabletView } = useScreenSize();
  const { isWhiteLabel } = useIsWhiteLabel();
  const { googleTrack } = useGoogleTracking();
  const { track } = useAnalytics();
  const { authStage } = useAuth();
  const currentUser = useCurrentUser();

  // derived state
  const vehicle = first(request.trip.routes).vehicle;
  const isPricelessBooking = useIsPricelessBooking({
    vehicle,
    authenticatedUser: currentUser,
  });
  const isCreatingQuote =
    !vehicle?.baseRateAutomation?.total && !isPricelessBooking;
  const trips = compact([request.trip, request.returnTrip]);

  // effect hooks
  usePageViewedTracking({
    trackName: "reserve_pageReviewed",
    trackProperties: {
      orderType: isPricelessBooking ? "reservationRequest" : "BRA",
    },
    skip: isCreatingQuote,
  });

  // effects
  useEffect(() => {
    googleTrack(
      isCreatingQuote
        ? "moovs_page_view_quote_summary"
        : isPricelessBooking
        ? "moovs_page_view_reservation_request_summary"
        : "moovs_page_view_reservation_summary"
    );
  }, [googleTrack, isCreatingQuote, isPricelessBooking]);

  useEffect(() => {
    if (!isCreatingQuote) {
      track(isPricelessBooking ? "request_pageOpened" : "reserve_pageOpened", {
        authentication:
          authStage === "authenticated" ? "logged-in" : "logged-out",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // return null to prevent error outs prior to redirect to /info that occurs when first landing on this page
  if (!vehicle?.id) return null;

  return (
    <>
      <Box width="100%" display="flex" justifyContent="center">
        <Box
          mt={2}
          mb={isMobileView ? 8 : 2}
          display="flex"
          flexDirection="column"
          width="100%"
          maxWidth="1140px"
        >
          {/* stepper */}
          <StatusStepper activeStep={2} hideLogin={isWhiteLabel} />
          {/* header */}
          <OrderDetailsHeader
            headerText={isCreatingQuote ? "Quote Request" : "Review & Reserve"}
            stage={
              isCreatingQuote
                ? RequestStage.Quote
                : isPricelessBooking
                ? RequestStage.Unconfirmed
                : RequestStage.Reservation
            }
            orderTypeSlug={request.orderType?.slug}
            trips={trips}
          />
          <Box display="flex" flexDirection={isTabletView ? "column" : "row"}>
            {/* left panel */}
            <TripDetailsCreateBlock
              trip={request.trip}
              returnTrip={request.returnTrip}
              bookingContact={request.bookingContact}
            />

            {/* right panel */}
            <Box
              display="flex"
              flexDirection="column"
              flex="2"
              {...(!isTabletView && { ml: 2 })}
            >
              {/* creating quote/reservation */}
              <Box mb={2} width="100%">
                {isCreatingQuote ? (
                  <CreateQuoteForm />
                ) : (
                  <PricelessBookingProvider
                    isPricelessBooking={isPricelessBooking}
                  >
                    <CreateReservationForm trips={trips} />
                  </PricelessBookingProvider>
                )}
              </Box>
              {/* hide contactUs b/c on white label that would point to swoop */}
              {!isWhiteLabel && (
                <Box width="100%">
                  <ContactUs />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* previous step */}
      <Box
        mb={6}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Button color="primary" onClick={history.goBack}>
          Previous Step
        </Button>
      </Box>
    </>
  );
}

export default ConfirmStep;
