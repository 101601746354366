import React from "react";
import first from "lodash/first";

import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Stop } from "types";

function PickupLocationColumn(props: GridRenderCellParams<string>) {
  const { row } = props;

  const pickupLocation = first(row.stops as unknown as Stop[]).location;

  return <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">{pickupLocation}</Typography>;
}

export default PickupLocationColumn;
