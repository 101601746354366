import React, { useEffect, useMemo } from "react";
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";

import { Card, Box, Divider } from "@mui/material";

import { currencyConverter as cc } from "utils/currency";
import {
  PriceOverviewBlock,
  DriverGratuityBlock,
  BookingContactBlock,
  PaymentMethodBlock,
  TermsAndConditionsBlock,
  SubmitButton,
  YourCardWillNotBeChargedBlock,
} from "components/confirm/form/components";
import { useAutomatedPricing, useOperator } from "globals/hooks";
import { useIsGratuityEnabled } from "components/confirm/form/hooks/useIsGratuityEnabled";
import { CreateReservationFormState } from "../form/schemaValidation";
import ConsentSignatureSubmitButton from "components/confirm/form/components/ConsentSignatureSubmitButton";
import MeetGreetBlock from "components/confirm/form/components/MeetGreetBlock";
import { useDisplayMeetGreet } from "components/confirm/form/hooks/useDisplayMeetGreet";
import { CreateTripState } from "pages/new/context/initialState";
import { TripCategory } from "types";
import { useAutoChargePayment } from "pages/new/hooks";
import SmsConsentMessage from "components/sms-consent/SmsConsentMessage";
import { useDisplayAdditionalItems } from "components/confirm/form/hooks";
import AdditionalItemsBlock from "components/confirm/form/components/AdditionalItemsBlock";
import { usePricelessBookingContext } from "../../../context";
import { ConfirmReservationFormState } from "pages/order/components/ConfirmReservationForm/form/schemaValidation";
import LuggageCountBlock from "components/confirm/TripDetailsBlock/components/TripItineraryCard/components/AdditionalTripInfoBlock/components/AdditionalTripInfoDialog/components/LuggageCountBlock";

type CreateReservationFormFieldsProps = {
  control: Control<CreateReservationFormState>;
  watch: UseFormWatch<CreateReservationFormState>;
  formId: string;
  onConsentSignatureDialogOpen: () => void;
  isSubmitting: boolean;
  trips: CreateTripState[];
  setValue: UseFormSetValue<any>;
  createReservationErrors: any;
  getValues: UseFormGetValues<any>;
};

function CreateReservationFormFields(props: CreateReservationFormFieldsProps) {
  const {
    control,
    watch,
    formId,
    onConsentSignatureDialogOpen,
    isSubmitting,
    trips,
    setValue,
    getValues,
    createReservationErrors,
  } = props;

  // derived state
  const {
    bookingContact,
    driverGratuityPctCustomerInput,
    paymentMethod,
    pickUpVariantSelected,
  } = createReservationErrors;

  const validToOpenSignatureDialog =
    !isEmpty(createReservationErrors) &&
    !bookingContact &&
    !driverGratuityPctCustomerInput &&
    !paymentMethod &&
    !pickUpVariantSelected;

  // contexts
  const { isPricelessBooking } = usePricelessBookingContext();

  // hooks
  const {
    operator: {
      enableCreditCardWhenBooking,
      settings: { requireSignatureWhenBooking },
    },
  } = useOperator();

  const pricing = useAutomatedPricing({
    trips,
    driverGratuityPctCustomerInput: watch("driverGratuityPctCustomerInput"),
    pickUpVariantSelected: watch("pickUpVariantSelected"),
    userAppliedPromoCode: watch("promoCodeCustomerInput"),
    additonalItemsForOutboundTrip: watch(`additonalItemsByTrip`)[0],
  });

  const { autoPaymentAmt, autoChargeButtonText, autoPaymentType } =
    useAutoChargePayment({ pricings: [pricing], skip: isPricelessBooking });

  const { displayMeetGreet, meetGreetAmt } = useDisplayMeetGreet({
    isAirportPickUpStop:
      !!trips[0].stops[0].airport?.icaoCode ||
      !!trips[1]?.stops[0]?.airport?.icaoCode,
    mode: "create",
    createPickUpAddressIsAirport:
      trips[0].stops[0].pickUpGooglePlaceTypes?.includes("airport") ||
      (trips[0].tripCategory === TripCategory.RoundTrip &&
        trips[1].stops[0].pickUpGooglePlaceTypes?.includes("airport")),
  });

  const isGratuityEnabled = useIsGratuityEnabled({
    trips,
    mode: isPricelessBooking ? "priceless" : "create",
  });

  const { displayAdditionalItems, additionItems } = useDisplayAdditionalItems({
    mode: "create",
    vehicle: trips[0].routes[0].vehicle,
  });

  const baseRateAmt = useMemo(() => {
    let baseRateAmtCents = 0;

    trips.forEach(({ routes }) => {
      const { baseRateAutomation, returnBaseRateAutomation } =
        first(routes)?.vehicle || {};

      const automatedBaseRate = baseRateAutomation?.total || null;
      const returnAutomatedBaseRate = returnBaseRateAutomation?.total || null;

      baseRateAmtCents += cc.toCents(automatedBaseRate || 0);
      baseRateAmtCents += cc.toCents(returnAutomatedBaseRate || 0);
    });

    return cc.toDollars(baseRateAmtCents);
  }, [trips]);

  const pickUpDateTime = trips[0].stops[0].dateTime;

  // effects
  useEffect(() => {
    setValue("autoPaymentAmt", autoPaymentAmt);
    setValue("autoPaymentType", autoPaymentType);
  }, [autoPaymentAmt, setValue, autoPaymentType]);

  return (
    <Card variant="outlined">
      <Box display="flex" flexDirection="column" m={3}>
        {/* Price Overview */}
        {(!isPricelessBooking || baseRateAmt !== 0) && (
          <PriceOverviewBlock
            pricings={[pricing]}
            orderTotalAmt={pricing.orderTotalAmt}
            pickUpDateTime={pickUpDateTime}
          />
        )}
        {/* Meet & Greet */}
        {displayMeetGreet && (
          <Box my={3}>
            <MeetGreetBlock control={control} meetGreetAmt={meetGreetAmt} />
          </Box>
        )}
        {/* Driver Gratuity */}
        {isGratuityEnabled && (
          <Box my={3}>
            <DriverGratuityBlock control={control} baseRateAmt={baseRateAmt} />
          </Box>
        )}
        {/* Additional Items */}
        {displayAdditionalItems && (
          <Box my={3}>
            <AdditionalItemsBlock
              {...additionItems}
              childSeatsFormPath={`additonalItemsByTrip.0.childSeats`}
            />
          </Box>
        )}
        {/* Divider, only render if any above are rendered */}
        {(!isPricelessBooking ||
          baseRateAmt !== 0 ||
          displayMeetGreet ||
          isGratuityEnabled ||
          displayAdditionalItems) && <Divider sx={{ mb: 3 }} />}
        {/* Luggage Count */}
        <LuggageCountBlock
          control={control}
          watch={watch}
          setValue={setValue}
          syncStateWithOutboundTrip={true}
        />
        {/* Booking Contact */}
        <Box mb={3}>
          <BookingContactBlock
            control={control}
            watch={watch}
            trackingFrom="reserve"
            setValue={setValue}
            getValues={getValues}
          />
        </Box>
        {/* Payment Method */}
        {enableCreditCardWhenBooking && (
          <PaymentMethodBlock
            control={control}
            trackingFrom="reserve"
            setValue={setValue}
          />
        )}
        {/* Terms and Conditions / Cancellation Policy */}
        <Box mb={3}>
          <TermsAndConditionsBlock
            trips={trips}
            isSubmitting={false}
            control={
              control as Control<
                CreateReservationFormState | ConfirmReservationFormState
              >
            }
          />
        </Box>
        {/* Submit Button */}
        {requireSignatureWhenBooking ? (
          <ConsentSignatureSubmitButton
            isSubmitting={isSubmitting}
            onConsentSignatureDialogOpen={onConsentSignatureDialogOpen}
            autoChargeButtonText={autoChargeButtonText}
            validToOpenSignatureDialog={validToOpenSignatureDialog}
            formId={formId}
          />
        ) : (
          <SubmitButton
            isSubmitting={isSubmitting}
            formId={formId}
            autoChargeButtonText={autoChargeButtonText}
          />
        )}

        {/* SMS Consent Message */}
        <SmsConsentMessage
          actionButtonName={autoChargeButtonText ? "Pay" : "Reserve"}
          typographySx={{ mt: 3 }}
        />

        {/* Your Card Will Not Be Charged */}
        {!autoPaymentAmt && enableCreditCardWhenBooking ? (
          <Box mt={2}>
            <YourCardWillNotBeChargedBlock />
          </Box>
        ) : null}
      </Box>
    </Card>
  );
}

export default CreateReservationFormFields;
