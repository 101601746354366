import get from "lodash/get";
import React, { ChangeEventHandler, KeyboardEvent } from "react";
import {
  InputAdornment,
  Box,
  CircularProgress,
  Button,
  Typography,
} from "@mui/material";
import { NumberFormatFlight } from "design-system/components/inputs/NumberFormat";

import { white, errorRed } from "design-system/colors";
import { ConditionalTextField } from "components/react-hook-form/text-field/ConditionalTextField";
import { useFormContext } from "react-hook-form";

type FlightNumberInputProps = {
  onFlightSearch: () => void;
  onFlightNumberInputChange?: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  onClearFlight: () => void;
  isLoading: boolean;
  airlineIata: string;
  hasFlight: boolean;
  error?: boolean;
  value?: string;
  label?: string;
  disabled?: boolean;

  // react hook form only props
  name?: string;
};

function FlightNumberInput(props: FlightNumberInputProps) {
  const {
    onFlightNumberInputChange,
    onFlightSearch,
    onClearFlight,
    hasFlight,
    airlineIata,
    error,
    value,
    label,
    name,
    isLoading,
    disabled,
  } = props;

  // Get form context to access errors
  const formContext = useFormContext();

  // Get the trackedFlight error if form context exists
  let trackedFlightError = null;
  if (formContext && name) {
    const { formState } = formContext;
    const firstStopFormPath = name.substring(0, name.lastIndexOf("."));
    const trackedFlightPath = `${firstStopFormPath}.trackedFlight`;
    trackedFlightError = get(formState.errors, trackedFlightPath)?.message;
  }

  // event handlers
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13 || event.key === "Enter") {
      onFlightSearch();
    }
  };

  const handleButtonClick = () => {
    if (hasFlight) {
      onClearFlight();
    } else {
      onFlightSearch();
    }
  };

  return (
    <Box flex="1" mb={1} display="flex" flexDirection="column">
      <Box display="flex" gap={1}>
        <ConditionalTextField
          name={name}
          label={label}
          variant="outlined"
          fullWidth
          onBlur={onFlightSearch}
          onChange={onFlightNumberInputChange}
          sx={{
            backgroundColor: white,
            "& .MuiInputBase-root": {
              lineHeight: 1.43,
            },
          }}
          error={error}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          InputProps={{
            inputComponent: NumberFormatFlight as any,
            ...(airlineIata && {
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ "& .MuiInputBase-root": { lineHeight: 1.43 } }}
                >
                  {airlineIata}
                </InputAdornment>
              ),
            }),
            ...(isLoading && {
              endAdornment: (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              ),
            }),
          }}
          // value only if provided, and removes all non-numeric characters
          // this is so we can provide the full flight number
          {...(typeof value === "string" && {
            value: value.replace(/\D/g, ""),
          })}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleButtonClick}
          sx={{
            height: "56px", // Standard height of MUI outlined text fields
          }}
          disabled={isLoading || disabled}
        >
          {hasFlight ? "Clear" : "Confirm"}
        </Button>
      </Box>

      {/* Display the trackedFlight error message below the button */}
      {trackedFlightError && (
        <Typography
          variant="caption"
          sx={{
            color: errorRed,
            mt: 0.5,
            textAlign: "right",
          }}
        >
          {trackedFlightError}
        </Typography>
      )}
    </Box>
  );
}

export default FlightNumberInput;
