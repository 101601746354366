export const getFormNameKeys = (
  formNameKey: "trip" | "returnTrip",
  stopsArrayIndex: number
) => {
  // form name keys
  const formNameTripStops = `${formNameKey}.stops` as
    | "trip.stops"
    | "returnTrip.stops";

  const formNameTripCategory = `${formNameKey}.tripCategory` as
    | "trip.tripCategory"
    | "returnTrip.tripCategory";

  const formNameTripStop = `${formNameTripStops}.${stopsArrayIndex}` as
    | "trip.stops.0"
    | "returnTrip.stops.0"; // coerce type so that the below watch can correctly infer the type

  const formNameLocationVariant = `${formNameTripStop}.variant` as
    | "trip.stops.0.variant"
    | "returnTrip.stops.0.variant";

  const formNameFlightNumber = `${formNameTripStop}.flightNumber` as
    | "trip.stops.0.flightNumber"
    | "returnTrip.stops.0.flightNumber";

  const formNameDateTime = `${formNameTripStop}.dateTime` as
    | "trip.stops.0.dateTime"
    | "returnTrip.stops.0.dateTime";

  const formNameLocationInput = `${formNameTripStop}.location` as
    | "trip.stops.0.location"
    | "returnTrip.stops.0.location";

  const formNameAirportInput = `${formNameTripStop}.airport` as
    | "trip.stops.0.airport"
    | "returnTrip.stops.0.airport";

  const formNameAirlineInput = `${formNameTripStop}.airline` as
    | "trip.stops.0.airline"
    | "returnTrip.stops.0.airline";

  const formNameTrackedFlight = `${formNameTripStop}.trackedFlight` as
    | "trip.stops.0.trackedFlight"
    | "returnTrip.stops.0.trackedFlight";

  const formNameSkipFlightDetails = `${formNameTripStop}.skipFlightDetails` as
    | "trip.stops.0.skipFlightDetails"
    | "returnTrip.stops.0.skipFlightDetails";

  const formNameIsLocationAirport = `${formNameTripStop}.isLocationAirport` as
    | "trip.stops.0.isLocationAirport"
    | "returnTrip.stops.0.isLocationAirport";

  return {
    formNameTripStops,
    formNameTripCategory,
    formNameTripStop,
    formNameLocationVariant,
    formNameFlightNumber,
    formNameDateTime,
    formNameLocationInput,
    formNameAirportInput,
    formNameAirlineInput,
    formNameTrackedFlight,
    formNameSkipFlightDetails,
    formNameIsLocationAirport,
  };
};
