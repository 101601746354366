import React from "react";

import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import RoundChip from "design-system/components/RoundChip"; 
import { tripGridStatusLabelColors } from "design-system/colors";
import {  RequestStage, Trip } from "types";

function StatusColumn(props: GridRenderCellParams<Trip>) {
  const { row } = props;

  const status = row.cancelledAt
    ? "CANCELLED"
    : row.request.stage === RequestStage.Reservation
      ? "CONFIRMED"
      : row.request.stage === RequestStage.Unconfirmed
      ? "UNCONFIRMED"
      : "CONFIRMED";

  return (
    <Box display="flex" justifyContent="center">
      <RoundChip {...tripGridStatusLabelColors[status]} />
    </Box>
  );
}

export default StatusColumn;
