import { TemporaryPassenger } from "types";
import { AdditionalTripInfoState } from "./schemaValidation";

export const getAdditionalTripInfoDefaultValues = (initialState: {
  totalGroupSize: number;
  note?: string;
  temporaryPassenger?: TemporaryPassenger;
  route?: {
    id: string | null;
    carryOnLuggage: number | null;
    checkedLuggage: number | null;
    oversizeLuggage: number | null;
  };
  selectedPassenger?: any;
}): AdditionalTripInfoState => ({
  totalGroupSize: null,
  note: "",
  ...initialState,
  // passenger contact (i.e. temporary passenger)
  temporaryPassenger: {
    name: "",
    ...initialState.temporaryPassenger,
    // phone fields pulled from temporary passenger to work with RHF international phone input
    phoneFields: {
      mobilePhone: "",
      mobilePhoneInternational: "",
      phoneCountryCode: "",
      phoneCountryDialCode: "",
      phoneCountryName: "",
      phoneCountryFormat: "",
      ...initialState.temporaryPassenger,
    },
  },
  route: initialState.route || {
    id: null,
    carryOnLuggage: 0,
    checkedLuggage: 0,
    oversizeLuggage: 0,
  },
  selectedPassenger: initialState.selectedPassenger || null,
});
