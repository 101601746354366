import React from "react";
import first from "lodash/first";
import moment from "moment";

import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Stop } from "types";

function PickupTimeColumn(props: GridRenderCellParams<string>) {
  const { row } = props;

  const pickupStop = first(row.stops as unknown as Stop[]);

  const timezone = pickupStop.timezoneId || moment.tz.guess();
  const offset = moment.tz(timezone).utcOffset();

  const displayTime = moment.utc(pickupStop.dateTime).add(-1 * (offset / 60), 'hour').tz(timezone).format('h:mm A z');

  return <Typography variant="body2">{displayTime}</Typography>;
}

export default PickupTimeColumn;
