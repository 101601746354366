import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Box, Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

import { OpenIcon } from "design-system/icons";
import { grayDark, white } from "design-system/colors";
import DuplicateTripDialog from "../DuplicateTripDialog";
import { useOperatorSlug } from "globals/hooks/useOperatorSlug";
import MoovsMenu from "components/globals/MoovsMenu";
import { useScreenSize } from "globals/hooks";

type ActionColumnProps = {
  refetch: () => void;
} & GridRenderCellParams<string>;

type MobileActionButtonProps = {
  onViewTripClick: () => void;
  onRepeatTripClick: () => void;
}

const MobileActionButton = (props: MobileActionButtonProps) => {
  const { onViewTripClick, onRepeatTripClick } = props;
  return (
    <MoovsMenu
      buttonContent={
        <MoreHorizIcon />
      }
      variant="contained"
      menuItems={[
        {
          label: "Repeat",
          icon: () => <LoopOutlinedIcon htmlColor={grayDark} sx={{ fontSize: 20 }} />,
          onClick: onRepeatTripClick,

        },
        {
          label: "View",
          icon: () => <OpenInNewOutlinedIcon htmlColor={grayDark} sx={{ fontSize: 20 }} />,
          onClick: onViewTripClick,
        },

      ]}
    />
  );
};

type DesktopActionButtonsProps = {
  onRepeatTripClick: () => void;
  onViewTripClick: () => void;
}

const DesktopActionButtons = (props: DesktopActionButtonsProps) => {
  const { onRepeatTripClick, onViewTripClick } = props;
  return (
    <>
      <Button variant="outlined" color="primary" onClick={onRepeatTripClick}>Repeat</Button>
      <Button variant="contained" sx={{ display: "flex", alignItems: "center", gap: 1 }} color="primary" onClick={onViewTripClick}>View<OpenIcon size="small" color={white} /></Button>
    </>
  );
};

function ActionColumn(props: ActionColumnProps) {
  const { row, refetch } = props;

  const history = useHistory();
  const operatorSlug = useOperatorSlug();
  const { isMobileView } = useScreenSize();

  // event handlers
  const handleViewTripClick = () => {
    history.push({
      pathname: `/${operatorSlug}/trip/${row.routes[0]?.publicId}`,
      state: {
        from: "orders",
      },
    });
  };

  const [isDuplicateTripDialogOpen, setIsDuplicateTripDialogOpen] = useState(false);

  return <Box display="flex" justifyContent="center" width="100%" gap={1}>
    <DuplicateTripDialog
      open={isDuplicateTripDialogOpen}
      onClose={() => setIsDuplicateTripDialogOpen(false)}
      tripId={row.id}
      request={row.request}
      refetch={refetch}
    />
    {isMobileView ? <MobileActionButton onViewTripClick={handleViewTripClick} onRepeatTripClick={() => setIsDuplicateTripDialogOpen(true)} /> : (
      <DesktopActionButtons onRepeatTripClick={() => setIsDuplicateTripDialogOpen(true)} onViewTripClick={handleViewTripClick} />
    )}
  </Box>
}


export default ActionColumn;
