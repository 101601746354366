import React, { useMemo, useState } from "react";
import size from "lodash/size";

import { Box, CircularProgress } from "@mui/material";
import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";

import EmptyTripsBlock from "./components/EmptyTripBlock";

import { contactTripsGridColumns } from "./components";
import { ApolloQueryResult } from "@apollo/client";
import { FetchMoreQueryOptions } from "@apollo/client";
import { Stop, Trip } from "types";
import StopsDialog from "./StopsDialog";
import CustomLoadingOverlay from "./components/CustomLoadingOverlay";
import { useScreenSize } from "globals/hooks";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

type ContactTripsGridProps = {
  sx?: Record<string, unknown>;
  hasNextPage: boolean;
  cursor: string;
  fetchMore: (options: FetchMoreQueryOptions<any, any>) => Promise<ApolloQueryResult<any>>;
  trips: Trip[];
  tripsLoading: boolean;
  refetch: () => void;
  refetchPastTrips: () => void;
};

function ContactTripsGrid(props: ContactTripsGridProps) {
  const { sx, trips, tripsLoading, fetchMore, hasNextPage, cursor, refetch, refetchPastTrips } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [stops, setStops] = useState<Stop[]>([]);
  const { isMobileView } = useScreenSize();
  const columns = useMemo(() => contactTripsGridColumns(refetch, refetchPastTrips, isMobileView), [refetch, refetchPastTrips, isMobileView]);

  // hooks

  const handleRowScrollEnd = () => {
    if (hasNextPage) {
      fetchMore({
        variables: {
          cursor,
        },
      });
    }
  };

  return (
    <Box height="100%" sx={sx} flexGrow={1}>
      <StopsDialog
        stops={stops}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      {tripsLoading && !size(trips) ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width="100%"
          height="100%"
        >
          <CircularProgress size={40} thickness={2} />
        </Box>
      ) : size(trips) ? (
        <DataGridPro
          disableColumnFilter
          disableSelectionOnClick
          disableChildrenSorting
          disableChildrenFiltering
          disableColumnMenu
          disableColumnReorder
          disableMultipleColumnsSorting
          aria-label="Contact Trip Table"
          paginationMode="server"
          sortingMode="server"
          loading={tripsLoading}
          onRowsScrollEnd={handleRowScrollEnd}
          columns={columns}
          rowCount={trips.length}
          rows={trips}
          initialState={{ pinnedColumns: { right: ['action'] } }}
          onCellClick={({ row, field }) => {
            if (field === 'stops') {
              setStops(row.stops);
              setIsOpen(true);
            }
          }}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
            NoRowsOverlay: () => <div>No results found</div>,
          }}
          componentsProps={{
            panel: {
              sx: {
                "& .MuiDataGrid-panelContent": { overflow: "unset" },
              },
            },
          }}
        />
      ) : (
        <EmptyTripsBlock />
      )}
    </Box>
  );
}
export default ContactTripsGrid;
