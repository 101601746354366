/**
 * Future improvements:
 * - break apart render into smaller subcomponents (ex: bundle flight fields into single component, abstract out drag-and-drop components)
 * - there are still a few props that can be removed if this component is further refactored.
 * - make AddressAirportToggle hook into the form directly (be uncontrolled). This requires creating a React Hook Form Checkbox component
 *  - https://github.com/Mohammad-Faisal/react-hook-form-material-ui/tree/master/src/form-components
 */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import last from "lodash/last";
import moment from "moment-timezone";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { alabaster, grayLight, orange, white } from "design-system/colors";
import { RearrangeIcon, WarningIcon } from "design-system/icons";
import { useAnalytics, useLaunchDarklyFlags, useSnackbar } from "globals/hooks";
import { dateFormatter } from "globals/utils/helpers";
import { OrderTypeEnum, RoundTripVariant, Stop, TripCategory } from "types";
import { RHFDateTimePicker } from "components/react-hook-form/date-time/RHFDateTimePicker";
import { orderTypeToEnumMap } from "utils/enumMaps";
import { useFlightTracking } from "../hooks/useFlightTracking";
import { InfoStepState } from "../../../context/InfoStepFormProvider";
import FlightInfoCard from "components/FlightInfoCard/FlightInfoCard";
import MoovsTooltip from "components/globals/MoovsTooltip";
import {
  LocationAutoComplete,
  AirportAutoComplete,
  AirlineAutoComplete,
} from "components/autocompletes";
import {
  AddressAirportToggle,
  AdditionalStopInfoButtonPanel,
  EditRemoveStopButtons,
  FlightNumberInput,
  AdditionalStopInfoDialog,
  MultipleFlightsDialog,
} from "./components";
import { getFormNameKeys } from "./utils/getFormNameKeys";
import { LOAD_AIRPORTS_QUERY } from "globals/graphql";
import { useLazyQuery } from "@apollo/client";

enum LocationVariant {
  ADDRESS = "address",
  AIRPORT = "airport",
}
export enum StopVariant {
  PICK_UP = "PICK_UP",
  DROP_OFF = "DROP_OFF",
  MIDDLE = "MIDDLE",
}

type CreateRequestStopsBlockItemType = {
  dndId: string;
  stopVariant: StopVariant;
  formNameKey: "trip" | "returnTrip";
  canRemoveStop: boolean;
  canOnlyUpdateStopDate?: boolean;
  onRemoveStop: () => void;
  showIncorrectStopOrderWarning: boolean;
  roundTripVariant: RoundTripVariant | undefined;

  // draggable props
  replacementStop: any; // used to replace rendered stop info when dragging over another stop
  isSourceIndex: boolean;
  isDestinationIndex: boolean;
  stopsArrayIndex?: number;
};

const { ADDRESS, AIRPORT } = LocationVariant;

function CreateRequestStopsBlockItem(props: CreateRequestStopsBlockItemType) {
  const {
    dndId,
    stopVariant,
    formNameKey,
    canRemoveStop,
    canOnlyUpdateStopDate,
    onRemoveStop,
    showIncorrectStopOrderWarning,
    roundTripVariant,

    // dragable props
    replacementStop,
    isSourceIndex,
    isDestinationIndex,
    stopsArrayIndex,
  } = props;

  // state
  const [additionalStopInfoOpen, setAdditionalStopInfoOpen] = useState(false);
  const { editStopInfo } = useLaunchDarklyFlags();

  // derived state
  const {
    formNameTripStops,
    formNameTripStop,
    formNameTripCategory,
    formNameLocationVariant,
    formNameFlightNumber,
    formNameDateTime,
    formNameLocationInput,
    formNameAirportInput,
    formNameAirlineInput,
    formNameTrackedFlight,
    formNameSkipFlightDetails,
    formNameIsLocationAirport,
  } = getFormNameKeys(formNameKey, stopsArrayIndex);

  // hooks
  const { track } = useAnalytics();
  const snackbar = useSnackbar();

  // React Hook Form props
  const { watch, setFocus, resetField, setValue, getValues, clearErrors } =
    useFormContext<InfoStepState>();
  const returnTripStops = useWatch({
    name: "returnTrip.stops",
  });

  const [
    stop,
    tripCategory,
    variant,
    orderType,
    skipFlightDetailsValue,
    isLocationAirportValue,
  ] = watch([
    formNameTripStop,
    formNameTripCategory,
    formNameLocationVariant,
    "orderType",
    formNameSkipFlightDetails,
    formNameIsLocationAirport,
  ]);

  const handleClearTrackedFlight = useCallback(() => {
    if (stop?.trackedFlight) {
      setValue(formNameTrackedFlight, null);
    }
  }, [formNameTrackedFlight, setValue, stop?.trackedFlight]);

  const { update } = useFieldArray({
    name: formNameTripStops,
  });

  // derived state
  const isPickUp = stopVariant === StopVariant.PICK_UP;
  const isDropOff = stopVariant === StopVariant.DROP_OFF;
  const isAddress = variant === ADDRESS;
  const isAirport = variant === AIRPORT;
  const shouldShowAdditionalStopInfoButton =
    !isPickUp && !canOnlyUpdateStopDate;
  const shouldHideDateTimePicker =
    tripCategory === TripCategory.RoundTrip ||
    tripCategory === TripCategory.OneWay ||
    isDropOff;

  // query
  const [setAirportByIataCode] = useLazyQuery(LOAD_AIRPORTS_QUERY, {
    onCompleted(data) {
      if (data.airports.edges.length > 0) {
        update(stopsArrayIndex, {
          ...stop,
          airport: data.airports.edges[0].node,
        });
      } else {
        snackbar.error("Can not find airport, please enter airport again.");
      }
    },
    onError() {
      snackbar.error("Can not find airport, please enter airport again.");
    },
    fetchPolicy: "network-only",
  });

  // flight hook
  const {
    multipleFlightsDialogData,
    isSearchingForFlight,
    trackedFlightLoading,
    handleStopFlightSearch,
    handleSearchFlightsOnEnterKeyPress,
    handleMultipleFlightSelect,
    handleMultipleFlightsDialogClose,
  } = useFlightTracking({
    isAirport,
    formNameTripStops,
    stopsArrayIndex,
    isDropOff,
  });

  // field labels
  const { stopLabel, airportLabel } = useMemo(() => {
    if (isPickUp)
      return { stopLabel: "Pick-up", airportLabel: "Arrival Airport" };
    if (isDropOff)
      return { stopLabel: "Drop-off", airportLabel: "Departure Airport" };

    return { stopLabel: `Stop ${stopsArrayIndex}`, airportLabel: "Airport" };
  }, [isDropOff, isPickUp, stopsArrayIndex]);

  // derived state for drag and drop render
  const { replacementStopLabel, replacementStopValue } = useMemo(
    () =>
      replacementStop?.airport
        ? {
            replacementStopLabel: airportLabel,
            replacementStopValue: `${replacementStop.airport.iataCode} - ${replacementStop.airport.airportName}`,
          }
        : {
            replacementStopValue: replacementStop?.location,
            replacementStopLabel: "Address",
          },
    [airportLabel, replacementStop?.airport, replacementStop?.location]
  );

  // helper functions
  // if linked return trip exists, updates it with passed in properties
  const updateReturnTripStopFields = useCallback(
    (returnTripStopFields: { [key: string]: any }) => {
      const linkedReturnTripStopIndex = returnTripStops?.findIndex(
        (returnTripStop) => returnTripStop.id === stop.id
      );

      // do not update if not round trip variant or if there's no linked stop
      if (
        tripCategory !== TripCategory.RoundTrip ||
        linkedReturnTripStopIndex === -1
      ) {
        return;
      }

      // derive the form name path to the returnTrip stop, and update that return trip stop with passed in stop fields
      const returnTripStopPath =
        `returnTrip.stops.${linkedReturnTripStopIndex}` as "returnTrip.stops.0"; // coerce type for RHF
      const returnTripStop = getValues(returnTripStopPath);

      setValue(returnTripStopPath, {
        ...returnTripStop,
        ...returnTripStopFields,
      });

      clearErrors(`${returnTripStopPath}.location`);
    },
    [clearErrors, getValues, returnTripStops, setValue, stop.id, tripCategory]
  );

  // event handlers
  const handleStopTypeToggle = (_, variant: LocationVariant) => {
    if (variant === null) return;

    setValue(formNameLocationVariant, variant);

    // Set isLocationAirport field for validation
    setValue(`${formNameTripStop}.isLocationAirport`, variant === AIRPORT);

    if (roundTripVariant !== RoundTripVariant.Return) {
      // set the return trip stop's variant
      updateReturnTripStopFields({
        variant,
      });
    }

    // focus on address / airport
    setTimeout(() => {
      setFocus(
        variant === AIRPORT ? formNameAirportInput : formNameLocationInput
      );
    }, 0);
  };

  const handleSecondaryChangeOnStopFields = (
    fieldValue,
    nameOfUpdatedField: string
  ) => {
    // only being used by location and airport autocompletes right now
    // we don't want the key to be the entire path, just the key within the stop
    const stopKey = last(nameOfUpdatedField.split("."));

    if (stopKey === "location") {
      updateReturnTripStopFields({
        [stopKey]: fieldValue?.description || fieldValue,
      });
      return;
    }

    // Clear tracked flight when airline changes
    if (stopKey === "airline") {
      handleClearTrackedFlight();
    }

    updateReturnTripStopFields({
      [stopKey]: fieldValue,
    });
  };

  const handleAdditionalStopInfoChange = (
    additionalStopInfo: Partial<Stop>
  ) => {
    const newStopFields = {
      ...additionalStopInfo,
      // if dateTime has changed
      ...(additionalStopInfo.dateTime !== stop.dateTime && {
        dateTime: moment(additionalStopInfo.dateTime).toISOString(),
        // for first flight, if dateTime changes, flight info should also reset
        ...(isPickUp && {
          flightNumber: null,
          trackedFlight: null,
        }),
      }),
    };

    update(stopsArrayIndex, { ...stop, ...newStopFields });
    updateReturnTripStopFields(newStopFields);
  };

  // set LocationVariant to airport/address based on OrderType
  useEffect(() => {
    if (!orderType || variant === "airport") return;
    const orderTypeSlug = orderTypeToEnumMap[orderType.slug];

    const isStopOnReturnTrip = roundTripVariant === RoundTripVariant.Return;
    const isStopOnOutboundTrip = roundTripVariant !== RoundTripVariant.Return;

    // if user selects airport or airport pick up
    // then the pickup stop on outbound trips AND dropoff stop on return trips will be toggled to airport
    // HOWEVER if a user decides they want to override w/ an Address, it will be preserved
    const shouldToggleAirportPickup =
      (orderTypeSlug === OrderTypeEnum.Airport ||
        orderTypeSlug === OrderTypeEnum.AirportPickUp) &&
      ((isStopOnOutboundTrip && isPickUp) ||
        (isStopOnReturnTrip && isDropOff)) &&
      getValues(formNameLocationInput) === "";

    // if user selects airport drop off
    // then the dropoff stop on outbound trips AND pickup stop on return trips will be toggled to airport
    // HOWEVER if a user decides they want to override w/ an Address, it will be preserved
    const shouldToggleAirportDropoff =
      orderTypeSlug === OrderTypeEnum.AirportDropOff &&
      ((isStopOnOutboundTrip && isDropOff) ||
        (isStopOnReturnTrip && isPickUp)) &&
      getValues(formNameLocationInput) === "";

    setValue(
      formNameLocationVariant,
      shouldToggleAirportPickup || shouldToggleAirportDropoff
        ? AIRPORT
        : ADDRESS
    );
    // do NOT trigger when isDropoff/isPickup change, because this resets the toggle during dragging
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType]);

  // reset the address/airport values when the variant changes
  useEffect(() => {
    if (variant === AIRPORT) {
      setValue(formNameIsLocationAirport, true);
      resetField(formNameLocationInput);
    } else if (variant === ADDRESS) {
      setValue(formNameIsLocationAirport, false);
      resetField(formNameAirportInput, { defaultValue: null });
    }
  }, [
    formNameAirportInput,
    formNameLocationInput,
    formNameTripStop,
    formNameIsLocationAirport,
    variant,
    setValue,
    resetField,
  ]);

  // Handles scenario where we went back to info step from previous step, ensuring flight info card is shown.
  useEffect(() => {
    if (stop?.trackedFlight) {
      setValue(formNameIsLocationAirport, true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isAirportPlace = (placeTypes: string[] | undefined) => {
    if (!placeTypes) return false;

    return placeTypes.some((type) => type === "airport");
  };

  return (
    <>
      <div>
        <Box display="flex" flexDirection="column">
          {/* first stop date & time */}
          {isPickUp && (
            <Box mb={4}>
              <Box mb={1}>
                <Typography variant="h4">Date & Time</Typography>
              </Box>
              <Box mb={1}>
                <Divider />
              </Box>
              <RHFDateTimePicker
                name={formNameDateTime}
                clearable={false}
                label={`${stopLabel} Date & Time`}
                inputFormat={dateFormatter(null, "abbreviated", {
                  mode: "dateTime",
                  returnFormatString: true,
                })}
                renderInputProps={{
                  name: "pickupDateTime",
                  required: true,
                  onBlur: handleStopFlightSearch(formNameAirlineInput),
                  onKeyDown: handleSearchFlightsOnEnterKeyPress,
                }}
                trackDateTimeSelected={track}
                displayConfirmTime
              />
            </Box>
          )}

          {/* stop header */}
          <Box
            display="flex"
            mb={1}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <Typography variant="h4">{stopLabel}</Typography>
              </Box>
              <AddressAirportToggle
                onChange={handleStopTypeToggle}
                value={variant || replacementStop?.variant}
              />
            </Box>

            <Box display="flex" alignItems="center">
              {showIncorrectStopOrderWarning && (
                <MoovsTooltip tooltipText="Date & time falls before a previous date & time">
                  <IconButton size="large">
                    <WarningIcon color={orange} />
                  </IconButton>
                </MoovsTooltip>
              )}
              {shouldShowAdditionalStopInfoButton && (
                <AdditionalStopInfoButtonPanel
                  stopLabel={stopLabel}
                  dateTime={!isPickUp && stop.dateTime}
                  groupSize={stop.groupSize}
                  note={stop.note}
                  onEditAdditionalStopInfoClick={() =>
                    setAdditionalStopInfoOpen(true)
                  }
                />
              )}

              {/* edit additional info / remove stop buttons */}
              {!isPickUp && (
                <EditRemoveStopButtons
                  canRemoveStop={canRemoveStop}
                  onRemoveStopClick={onRemoveStop}
                  onEditAdditionalStopInfoClick={() =>
                    setAdditionalStopInfoOpen(true)
                  }
                  showAdditionalStopInfoButton={editStopInfo}
                  stopLabel={stopLabel}
                />
              )}
            </Box>
          </Box>

          <Box>
            <Divider />
          </Box>
          <Droppable droppableId={dndId} direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{
                  backgroundColor: snapshot.isDraggingOver ? alabaster : white,
                  borderRadius: "4px",
                  height: "72px",
                  // prevents background from going on top of dragging item
                  zIndex: snapshot.isDraggingOver ? 900 : 1000,
                }}
                {...provided.droppableProps}
              >
                {/* temp draggable value */}
                {/* used to visually rearrange stops while dragging */}
                {replacementStop && !isDestinationIndex && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    flex="1"
                    alignItems="center"
                    bgcolor={white}
                    pl={2}
                    pr={0}
                    py={1}
                    mb={2}
                    borderRadius="4px"
                  >
                    <Box
                      mr={1.5}
                      height={56}
                      display="flex"
                      alignItems="center"
                    >
                      <RearrangeIcon />
                    </Box>
                    <Box display="flex" flex="1" width="100%">
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        label={replacementStopLabel}
                        value={replacementStopValue}
                      />
                    </Box>
                  </Box>
                )}
                <Draggable index={stopsArrayIndex} draggableId={dndId}>
                  {(provided, draggableSnapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      {/* address/airport reorderable input */}
                      <Box
                        display={
                          replacementStop && !isSourceIndex ? "none" : "flex"
                        }
                        flexDirection="row"
                        flex="1"
                        alignItems="center"
                        bgcolor={white}
                        boxShadow={
                          draggableSnapshot.isDragging
                            ? "0px 0px 30px rgba(0, 0, 0, 0.08)"
                            : "none"
                        }
                        py={1}
                        mb={2}
                        pl={2}
                        pr={draggableSnapshot.isDragging ? 2 : 0}
                        borderRadius="4px"
                      >
                        <Box
                          mr={1.5}
                          height={56}
                          display="flex"
                          alignItems="center"
                          {...provided.dragHandleProps}
                        >
                          <RearrangeIcon />
                        </Box>
                        <Box display="flex" flex="1" width="100%">
                          {/* address */}
                          <Box display={isAddress ? "flex" : "none"} flex="1">
                            <LocationAutoComplete
                              name={formNameLocationInput}
                              onAdditionalAction={
                                handleSecondaryChangeOnStopFields
                              }
                              label="Address"
                              transform={{
                                output: (value) => {
                                  if (isPickUp && value) {
                                    track("bookingTool_pickUpSelected", {
                                      locationType: "address",
                                    });
                                  } else if (isDropOff && value) {
                                    track("bookingTool_dropOffSelected", {
                                      locationType: "address",
                                    });
                                  }

                                  const stop = getValues(
                                    `trip.stops.${stopsArrayIndex}`
                                  );

                                  const locationIsAirport = isAirportPlace(
                                    value?.types
                                  );

                                  if (locationIsAirport) {
                                    // airport address usually has the format "Airport Name (IATA Code)"
                                    const airportIataCodeMatch =
                                      value.description.match(/\(([^)]+)\)/);
                                    const airportIataCode = airportIataCodeMatch
                                      ? airportIataCodeMatch[1]
                                      : "";

                                    setAirportByIataCode({
                                      variables: {
                                        iataCode: airportIataCode,
                                      },
                                    });

                                    return handleStopTypeToggle(null, AIRPORT);
                                  }

                                  setValue(
                                    formNameIsLocationAirport,
                                    locationIsAirport
                                  );

                                  setValue(`trip.stops.${stopsArrayIndex}`, {
                                    ...stop,
                                    pickUpGooglePlaceTypes: value?.types,
                                  });

                                  updateReturnTripStopFields({
                                    pickUpGooglePlaceTypes: value?.types,
                                  });

                                  return value?.description;
                                },
                              }}
                              fullWidth
                              required
                              disabled={canOnlyUpdateStopDate}
                            />
                          </Box>

                          {/* airport */}
                          <Box display={isAirport ? "flex" : "none"} flex="1">
                            <AirportAutoComplete
                              name={formNameAirportInput}
                              onAdditionalAction={
                                handleSecondaryChangeOnStopFields
                              }
                              transform={{
                                output: (value) => {
                                  if (isPickUp && value) {
                                    track("bookingTool_pickUpSelected", {
                                      locationType: "airport",
                                    });
                                  } else if (isDropOff && value) {
                                    track("bookingTool_dropOffSelected", {
                                      locationType: "airport",
                                    });
                                  }

                                  return value;
                                },
                                input: (value) => value?.airportName,
                              }}
                              fullWidth
                              required
                              label={airportLabel}
                              onBlur={handleStopFlightSearch(
                                formNameAirportInput
                              )}
                              onKeyDown={handleSearchFlightsOnEnterKeyPress}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  )}
                </Draggable>
                <Box visibility="hidden" height={0}>
                  {provided.placeholder}
                </Box>
              </div>
            )}
          </Droppable>
        </Box>
        {isLocationAirportValue && (
          <Box
            mt={2}
            p={2}
            mb={2}
            style={{ backgroundColor: alabaster, borderRadius: "4px" }}
          >
            {/* airport stop type additional info fields */}
            <>
              {/* airline */}
              <Box flex="1" mb={1}>
                <AirlineAutoComplete
                  name={formNameAirlineInput}
                  fullWidth
                  transform={{
                    input: (value) => value?.airlineName,
                  }}
                  label="Airline"
                  onBlur={handleStopFlightSearch(formNameAirlineInput)}
                  onKeyDown={handleSearchFlightsOnEnterKeyPress}
                  value={stop.airline}
                  disabled={skipFlightDetailsValue}
                />
              </Box>

              {/* flight number */}
              <Box flex="1" mb={1} display="flex">
                <FlightNumberInput
                  label="Flight"
                  name={formNameFlightNumber}
                  onFlightSearch={() => {
                    clearErrors(formNameTrackedFlight);
                    handleStopFlightSearch(formNameFlightNumber)();
                  }}
                  isLoading={isSearchingForFlight}
                  airlineIata={stop?.airline?.iataCode}
                  disabled={skipFlightDetailsValue}
                  hasFlight={!!stop?.trackedFlight}
                  onClearFlight={handleClearTrackedFlight}
                />
              </Box>

              <Box mb={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={skipFlightDetailsValue}
                      onChange={(e) => {
                        if (e.target.checked) {
                          resetField(formNameAirlineInput, {
                            defaultValue: null,
                          });
                          resetField(formNameFlightNumber, {
                            defaultValue: null,
                          });
                          resetField(formNameTrackedFlight, {
                            defaultValue: null,
                          });
                        }
                        setValue(formNameSkipFlightDetails, e.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label="I do not have my flight information"
                />
              </Box>

              {stop.trackedFlight && !trackedFlightLoading && (
                <Box>
                  <FlightInfoCard trackedFlight={stop.trackedFlight} />
                </Box>
              )}

              {trackedFlightLoading && (
                <Box
                  py={10}
                  style={{ backgroundColor: grayLight, borderRadius: "4px" }}
                  display="flex"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              )}
            </>
          </Box>
        )}
      </div>

      <AdditionalStopInfoDialog
        formNameKey={formNameTripStop}
        clearable={!isPickUp}
        stopLabel={stopLabel}
        open={additionalStopInfoOpen}
        onClose={() => setAdditionalStopInfoOpen(false)}
        stop={stop}
        canOnlyUpdateStopDate={canOnlyUpdateStopDate}
        onSave={handleAdditionalStopInfoChange}
        shouldHideDateTimePicker={shouldHideDateTimePicker}
      />

      {!!multipleFlightsDialogData && (
        <MultipleFlightsDialog
          open={!!multipleFlightsDialogData}
          onClose={handleMultipleFlightsDialogClose}
          onAccept={handleMultipleFlightSelect}
          data={multipleFlightsDialogData}
        />
      )}
    </>
  );
}

export default CreateRequestStopsBlockItem;
