import React from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { grayDark } from "design-system/colors";

import DateColumn from "./DateColumn";
import PassengerColumn from "./PassengerColumn";
import StatusColumn from "./StatusColumn";
import PickupTimeColumn from "./PickupTimeColumn";
import PickupLocationColumn from "./PickupLocationColumn";
import DropoffLocationColumn from "./DropoffLocationColumn";
import StopsColumn from "./StopsColumn";
import NumberOfPassengersColumn from "./NumberOfPassengersColumn";
import VehicleColumn from "./VehicleColumn";
import ActionColumn from "./ActionColumn";
import OrderTypeColumn from "./OrderTypeColumn";
import FavoriteTripColumn from "./FavoriteTripColumn";

const defaults = {
  sortable: false,
  hide: false,
  color: grayDark,
  width: 130,
};

export const contactTripsGridColumns = (refetch: () => void, refetchPastTrips: () => void, isMobileView: boolean): GridColumns => [
  {
    ...defaults,
    field: "select",
    headerName: "",
    renderCell: (params: GridRenderCellParams) => (
      <FavoriteTripColumn {...params} refetch={refetch} refetchPastTrips={refetchPastTrips} />
    ),
    align: "left",
    width: 50,
  },
  {
    ...defaults,
    field: "date",
    headerName: "Date",
    renderCell: DateColumn,
    align: "left",
  },
  {
    ...defaults,
    field: "orderType",
    headerName: "Order Type",
    renderCell: OrderTypeColumn,
    align: "left",
    width: 200,
  },
  {
    ...defaults,
    field: "pickupTime",
    headerName: "Pickup Time",
    renderCell: PickupTimeColumn,
    width: 200,
    align: "left",
  },
  {
    ...defaults,
    field: "pickupLocation",
    headerName: "Pickup Location",
    renderCell: PickupLocationColumn,
    align: "left",
    width: 200,
  },
  {
    ...defaults,
    field: "dropoffLocation",
    headerName: "Dropoff Location",
    renderCell: DropoffLocationColumn,
    align: "left",
    width: 200,
  },
  {
    ...defaults,
    field: "stops",
    headerName: "Stops",
    renderCell: StopsColumn,
    headerAlign: "center",
    align: "center",
    width: 200,
  },
  {
    ...defaults,
    field: "status",
    headerName: "Status",
    renderCell: StatusColumn,
    width: 150,
  },
  {
    ...defaults,
    field: "passenger",
    headerName: "Passenger",
    renderCell: PassengerColumn,
    width: 200,
  },
  {
    ...defaults,
    field: "numberOfPassengers",
    headerName: "# of Passengers",
    headerAlign: "left",
    align: "left",
    renderCell: NumberOfPassengersColumn,
    width: 150,
  },
  {
    ...defaults,
    field: "vehicle",
    headerName: "Vehicle",
    renderCell: VehicleColumn,
    width: 200,
  },
  {
    ...defaults,
    field: "action",
    headerName: "Action",
    headerAlign: isMobileView ? "center" : "left",
    renderCell: (params: GridRenderCellParams) => (
      <ActionColumn {...params} refetch={refetch} />
    ),
    width: isMobileView ? 90 : 210
  }
];

export default contactTripsGridColumns;
