import React from "react";
import moment from "moment-timezone";
import first from "lodash/first";
import { useMutation } from "@apollo/client";

import { StarBorder } from "@mui/icons-material";
import { Star } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Stop, Trip } from "types";

import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { useSnackbar } from "globals/hooks";
import { FAVORITE_TRIP_MUTATION } from "globals/graphql";


type FavoriteTripColumnProps = {
    refetch: () => void;
    refetchPastTrips: () => void;
} & GridRenderCellParams<Trip>;

function FavoriteTripColumn(props: FavoriteTripColumnProps) {
    const { row, refetch, refetchPastTrips } = props;
    const snackbar = useSnackbar();
    const tripPickUpDate = moment
        .utc(first(row.stops as unknown as Stop[]).dateTime)
        .format("M/DD/YY");
    const isPastTrip = moment(tripPickUpDate).isBefore(moment());

    const [favoriteTrip] = useMutation(
        FAVORITE_TRIP_MUTATION,
        {
            onCompleted(_, options) {
                const isFavorited = options.variables.input.isFavorite;
                snackbar.success(isFavorited ? "You favorited this trip!" : "You removed this trip from your favorites!");
                if (!isPastTrip) {
                    refetch();

                }
                if (isPastTrip) {
                    refetchPastTrips();
                }
            },
            onError(error) {
                snackbar.error(
                    getErrorMessage(error) ||
                    "There were issues favoriting this trip"
                );
            },
        }
    );

    const handleClick = () => {
        favoriteTrip({
            variables: {
                input: {
                    tripId: row.id,
                    isFavorite: !row.isFavorite,
                },
            },
        });
    }

    return (
        <Tooltip
            title={
                row.isFavorite ? "Remove trip from your favorites" : "Add trip to your favorites"
            }
            placement="top"
            followCursor
        >
            <IconButton onClick={handleClick}>
                {row.isFavorite ? <Star color="primary" /> : <StarBorder color="primary" />}
            </IconButton>
        </Tooltip>
    );
}

export default FavoriteTripColumn;