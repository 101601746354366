import React, { useEffect, useState } from "react";
import size from "lodash/size";
import { useMutation } from "@apollo/client";

import { Box } from "@mui/material";

import MoovsDialog from "components/globals/MoovsDialog";
import MoovsMultiDatePicker from "components/globals/MoovsMultiDatePicker";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { REPEAT_TRIP_MUTATION } from "globals/graphql";
import { Request } from "types";

type DuplicateTripDialogProps = {
  open: boolean;
  onClose: () => void;
  request: Request;
  tripId: string;
  isParentDrawer?: boolean;
  refetch: () => void;
};

function DuplicateTripDialog(props: DuplicateTripDialogProps) {
  const { open, onClose, tripId, refetch } = props;

  // derived state

  // hooks
  const { track } = useAnalytics();
  const snackbar = useSnackbar();

  // state
  const [dateList, setDateList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // mutations
  const [repeatTrip] = useMutation(REPEAT_TRIP_MUTATION, {
    onCompleted(data) {
      setIsSubmitting(false);

      const reasonOfFailure = data.repeatTrip.result?.reason;

      if (reasonOfFailure) {
        snackbar.error(reasonOfFailure);
        return;
      }

      const requests = data.repeatTrip.result.requests;

      snackbar.success("Successfully duplicated trip");
      track("trip_duplicated", {
        numberOfTripsDuplicated: size(requests),
      });

      clearListAndClose();
      refetch();
    },
    onError(error) {
      setIsSubmitting(false);
      snackbar.error(error.message);
    },
    refetchQueries: ["LoadTripConflict"],
  });

  // event handlers
  const handleSubmit = () => {
    setIsSubmitting(true);
    setErrorMsg(null);

    if (size(dateList) < 1) {
      setIsSubmitting(false);
      setErrorMsg("Please select at least one date");
      return;
    }

    const tripDates = dateList.map((momentDate) =>
      momentDate.format("YYYY-MM-DD")
    );

    repeatTrip({
      variables: {
        input: {
          tripId,
          dates: tripDates,
        },
      },
    });
  };

  const clearListAndClose = () => {
    setDateList([]);
    onClose();
  };

  const handleOnClose = () => {
    if (isSubmitting) return;

    clearListAndClose();
  };

  // effects
  useEffect(() => {
    if (!!errorMsg && size(dateList)) setErrorMsg(null);
  }, [errorMsg, dateList]);

  return (
    <MoovsDialog
      open={open}
      onClose={handleOnClose}
      dialogTitle={"Duplicate Trip"}
      size="sm"
      isXsFullscreenMobile
      acceptButtonText="Duplicate"
      closeButtonText="Cancel"
      onAccept={handleSubmit}
      acceptDisabled={isSubmitting}
    >
      <Box display="flex" flex="1" alignItems="center" justifyContent="center">
        <MoovsMultiDatePicker
          dateList={dateList}
          setDateList={setDateList}
          dateLimit={10}
          errorMsg={errorMsg}
        />
      </Box>
    </MoovsDialog>
  );
}

export default DuplicateTripDialog;
