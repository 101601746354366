import React from "react";

import { Box } from "@mui/material";

import { grayDark } from "../../../../../../../../design-system/colors";
import {
  NoteIcon,
  AirportIcon,
} from "../../../../../../../../design-system/icons";
import { parseNumberFromFlightNumber } from "../../../../../../../../utils/helpers";
import MoovsIconPanel from "../../../../../../../globals/MoovsIconPanel";
import DurationDisplay from "../../DurationDisplay";
import PassengerCountDisplay from "../../PassengerCountDisplay";
import { Stop } from "../../../../../../../../types";
import { getFlightTimes } from "../../../../../../../FlightInfoCard/flightTimeHelpers";

type MoreInfoPanelProps = {
  stop: Omit<Stop, "id" | "__typename" | "createdAt" | "updatedAt">;
  stopLabel: string;
  estimatedDropOffTime: string;
};

function MoreInfoPanel(props: MoreInfoPanelProps) {
  const { stop, stopLabel, estimatedDropOffTime } = props;

  const airportInfoText = `${stop.airline?.airlineName} - ${
    stop.airline?.iataCode
  } ${
    stop.trackedFlight
      ? parseNumberFromFlightNumber(stop.trackedFlight?.flightNumber)
      : ""
  }`;

  const isDeparture =
    stop.airport?.icaoCode === stop.trackedFlight?.origin?.airport?.icaoCode;

  const [departureTime, arrivalTime] = getFlightTimes(stop.trackedFlight);

  const flightInfoText = isDeparture
    ? `Departs at ${departureTime}`
    : `Arrives at ${arrivalTime}`;

  const iconPanelItems = [
    ...(!!stop.note
      ? [
          {
            icon: <NoteIcon color={grayDark} />,
            renderItem: !!stop.note,
            mainText: "Note:",
            subText: stop.note,
          },
        ]
      : []),
    ...(!!stop.trackedFlight
      ? [
          {
            icon: <AirportIcon color={grayDark} />,
            renderItem: true,
            mainText: "Flight Info:",
            subText: stop.trackedFlight
              ? [airportInfoText, flightInfoText]
              : [airportInfoText],
          },
        ]
      : []),
  ];

  const renderDuration =
    stop.stopIndex !== 1 && !!(stop.dateTime || estimatedDropOffTime);
  const renderPassengerCount = !!stop.groupSize;
  const renderIconPanel = !!iconPanelItems.length;

  return (
    <>
      {(renderDuration || renderPassengerCount || renderIconPanel) && (
        <Box mt={1.5} display="flex" flexDirection="row" alignItems="center">
          {renderDuration && (
            <Box mr={2}>
              <DurationDisplay
                dateTime={stop.dateTime || estimatedDropOffTime}
                isEstimated={!stop.dateTime}
              />
            </Box>
          )}
          {renderPassengerCount && (
            <Box mr={2}>
              <PassengerCountDisplay passengerCount={stop.groupSize} />
            </Box>
          )}
          {renderIconPanel && (
            <Box>
              <MoovsIconPanel
                items={iconPanelItems}
                bottomDrawerHeader={`Additional ${stopLabel} Info`}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default MoreInfoPanel;
