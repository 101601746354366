import React from "react";

import { Typography } from "@mui/material";

function StopsColumn() {
 
  return <Typography variant="body2" fontWeight={500} color="primary">Show Stops</Typography>;
}

export default StopsColumn;
