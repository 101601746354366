import React from "react";
import { Place } from "@mui/icons-material";
import { Avatar } from "@mui/material";

import { ListItemAvatar } from "@mui/material";

import { ListItem, ListItemText } from "@mui/material";

import { Box } from "@mui/material";

import { List } from "@mui/material";
import MoovsDialog from "components/globals/MoovsDialog";
import { Stop } from "types";

type StopsDialogProps = {
  stops: Stop[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function StopsDialog(props : StopsDialogProps) {
    const { stops, isOpen, setIsOpen } = props;

  return  <MoovsDialog
  open={isOpen}
  onClose={() => setIsOpen(false)}
  dialogTitle="Stops">
    <Box>
    <List>
  {stops.map((stop) => (
      <ListItem>
      <ListItemAvatar>
        <Avatar>
          <Place />
        </Avatar>
      </ListItemAvatar>
      <ListItemText 
        primary={stop.stopIndex === 1 
          ? "Pickup" 
          : stop.stopIndex === stops.length 
            ? "Dropoff" 
            : `Stop ${stop.stopIndex -1}`
        } 
        secondary={stop.location} 
      />
    </ListItem>
  ))}

</List>
</Box>
  </MoovsDialog>
}