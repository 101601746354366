import React, {
  ChangeEvent,
  useMemo,
  useRef,
  useState,
  KeyboardEvent,
  Dispatch,
} from "react";
import { SetStateAction } from "react-router/node_modules/@types/react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import moment from "moment-timezone";

import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  CircularProgress,
} from "@mui/material";

import { Airport, Airline, Stop, TrackedFlight } from "types";
import {
  alabaster,
  white,
  orange,
  grayLight,
  errorRed,
  black,
} from "design-system/colors";
import { RearrangeIcon, WarningIcon } from "design-system/icons";
import { useScreenSize, useSnackbar } from "globals/hooks";
import { applyUTCOffsetToTime, dateFormatter } from "globals/utils/helpers";
import AddressAirportToggle from "./CreateRequestStopsBlockItem/components/AddressAirportToggle";
import AdditionalStopInfoDialog from "./CreateRequestStopsBlockItem/components/AdditionalStopInfoDialog";
import EditRemoveStopButtons from "./CreateRequestStopsBlockItem/components/EditRemoveStopButtons";
import AdditionalStopInfoButtonPanel from "./CreateRequestStopsBlockItem/components/AdditionalStopInfoButtonPanel";
import FlightNumberInput from "./CreateRequestStopsBlockItem/components/FlightNumberInput";
import MultipleFlightsDialog from "./CreateRequestStopsBlockItem/components/MultipleFlightsDialog";
import {
  LocationAutoComplete,
  AirportAutoComplete,
  AirlineAutoComplete,
} from "components/autocompletes";
import FlightInfoCard from "components/FlightInfoCard/FlightInfoCard";
import MoovsTooltip from "components/globals/MoovsTooltip";
import { parseNumberFromFlightNumber } from "utils/helpers";
import { usePotentialTrackedFlightQuery } from "./hooks/usePotentialTrackedFlightQuery";
import MoovsDateTimePickerWithConfirm from "components/globals/MoovsDateTimePickerWithConfirm";

type StopsBlockItemType = {
  onStopChange: (stop: any) => void;
  stop: any;
  stopsLength: number;
  firstStop: any;
  requestErrors: any;
  setRequestErrors: any;
  canRemoveStop: boolean;
  canOnlyUpdateStopDate?: boolean;
  addressRef: any;
  onRemoveStop: () => void;
  showIncorrectStopOrderWarning: boolean;
  disableEditingLastDateTime: boolean; // not currently being used
  // draggable props
  nextStop: any;
  isSourceIndex: boolean;
  isDestinationIndex: boolean;
  setIsFetchingFlightInfo?: Dispatch<SetStateAction<boolean>>;
};

function StopsBlockItem(props: StopsBlockItemType) {
  const {
    onStopChange,
    stop,
    firstStop,
    stopsLength,
    canRemoveStop,
    canOnlyUpdateStopDate,
    requestErrors,
    setRequestErrors,
    addressRef,
    onRemoveStop,
    showIncorrectStopOrderWarning,
    // dragable props
    nextStop,
    isSourceIndex,
    isDestinationIndex,
    setIsFetchingFlightInfo,
  } = props;

  // hooks
  const snackbar = useSnackbar();
  const { isMobileView } = useScreenSize();

  // state
  const [additionalStopInfoOpen, setAdditionalStopInfoOpen] = useState(false);
  const [multipleFlightsDialogData, setMultipleFlightsDialogData] = useState<{
    flights: TrackedFlight[];
    flightNumber: string;
    inputAirportIcao: string;
  }>(undefined);
  const [flightNumberInput, setFlightNumberInput] = useState(
    stop?.flightNumber?.replace(/\D/g, "")
  );
  const airportRef = useRef(null);

  const stopsErrors = requestErrors.stops || [];
  const stopErrors = requestErrors.stops[stop.stopIndex - 1] || {};

  // queries
  const { loadPotentialTrackedFlight, potentialTrackedFlightLoading } =
    usePotentialTrackedFlightQuery({
      stop,
      onStopChange,
      setMultipleFlightsDialogData,
      setIsFetchingFlightInfo,
    });

  const stopLabel = useMemo(() => {
    const { stopIndex } = stop;

    if (stopIndex === 1) return "Pick-up";
    if (stopIndex === stopsLength) return "Drop-off";

    return `Stop ${stopIndex - 1}`;
  }, [stop, stopsLength]);

  const getAirportLabel = (stopIndex: number) => {
    if (stopIndex === 1) return "Arrival Airport";
    if (stopIndex === stopsLength) return "Departure Airport";

    return "Airport";
  };

  let nextStopValue = nextStop?.location;
  let nextStopLabel = "Address";

  if (nextStop?.airport) {
    const { airport, stopIndex } = nextStop;

    nextStopValue = `${airport.iataCode} - ${airport.airportName}`;
    nextStopLabel = getAirportLabel(stopIndex);
  }

  // event handlers
  const handleStopTypeToggle = (_, variant: "address" | "airport") => {
    if (variant !== null) {
      onStopChange({
        ...stop,
        variant,
        ...(variant === "address" && {
          airport: null,
          airline: null,
          flightNumber: null,
          trackedFlight: null,
        }),
        ...(variant === "airport" && {
          location: "",
        }),
      });
    }

    stopsErrors[stop.stopIndex - 1] = {
      ...stopErrors,
      dateTime: "",
    };

    setRequestErrors({
      ...requestErrors,
      stops: stopsErrors,
    });

    // focus on address / airport
    setTimeout(() => {
      if (variant === "address") addressRef?.current?.focus();
      if (variant === "airport") airportRef?.current?.focus();
    }, 0);
  };

  const handleStopFlightNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsFetchingFlightInfo && setIsFetchingFlightInfo(true);

    const inputFlightNumber = e.target.value;
    stopsErrors[stop.stopIndex - 1] = {
      ...stopErrors,
      flightNumber: "",
    };

    setRequestErrors({
      ...requestErrors,
      stops: stopsErrors,
    });

    setFlightNumberInput(inputFlightNumber);
  };

  const handleStopFlightSearch = async () => {
    onStopChange({
      trackedFlight: null,
      flightNumber: null,
    });

    const currentStopErrors = {
      airport: !stop.airport ? "Please select an airport" : "",
      airline: !stop.airline ? "Please select an airline" : "",
      flightNumber: !flightNumberInput ? "Please enter flight number" : "",
      dateTime: !firstStop.dateTime ? "Please enter flight number" : "",
    };

    const hasErrors =
      Object.values(currentStopErrors).some((value) => value.length) ||
      !firstStop.dateTime;

    if (stop.stopIndex !== 1) {
      // sets error for when pickup dateTime is not set while searching on non-pickup stop
      requestErrors.stops[0] = {
        ...requestErrors.stops[0],
        dateTime: !firstStop.dateTime ? "Please enter flight time" : "",
      };
    }

    stopsErrors[stop.stopIndex - 1] = {
      ...stopErrors,
      ...currentStopErrors,
    };

    setRequestErrors({
      ...requestErrors,
      stops: stopsErrors,
    });

    if (hasErrors) {
      snackbar.warning("Please enter missing information to search flights", {
        snackbarColor: white,
        iconColor: errorRed,
        textColor: black,
      });
      return;
    }

    setIsFetchingFlightInfo(true);
    loadPotentialTrackedFlight({
      variables: {
        flightNumber: parseFloat(flightNumberInput),
        firstStopDateTime: firstStop.dateTime,
        airline: stop.airline?.icaoCode,
        ...{
          // use departureAirport for dropoffs
          ...(stop.stopIndex === stopsLength
            ? { departureAirport: stop.airport?.icaoCode }
            : { arrivalAirport: stop.airport?.icaoCode }),
        },
      },
    });
  };

  const handleClearTrackedFlight = () => {
    const updatedStop = {
      ...stop,
      trackedFlight: null,
      flightNumber: null,
    };
    onStopChange(updatedStop);
    setFlightNumberInput("");
  };

  const handleMultipleFlightSelect = (selectedFlight: TrackedFlight) => {
    onStopChange({
      trackedFlight: selectedFlight,
      flightNumber: parseNumberFromFlightNumber(selectedFlight.flightNumber),
    });
    setMultipleFlightsDialogData(undefined);
  };

  const handleFirstStopDateTimeChange = (date: any) => {
    const newDateTime = date ? applyUTCOffsetToTime(date, "subtract") : null;

    onStopChange({
      dateTime: moment(newDateTime).toISOString(),
      flightNumber: null,
      trackedFlight: null,
    });

    stopsErrors[stop.stopIndex - 1] = {
      ...stopErrors,
      dateTime: "",
    };

    setRequestErrors({
      ...requestErrors,
      stops: stopsErrors,
    });
  };

  const handleStopLocationChange = (value) => {
    onStopChange({ location: value || "" });

    stopsErrors[stop.stopIndex - 1] = {
      ...stopErrors,
      location: "",
    };

    setRequestErrors({
      ...requestErrors,
      stops: stopsErrors,
    });
  };

  const handleStopChange = (key, value) => {
    onStopChange({
      [key]: value,
      ...((key === "airline" || key === "airport") && {
        flightNumber: null,
        trackedFlight: null,
      }),
    });

    stopsErrors[stop.stopIndex - 1] = {
      ...stopErrors,
      [key]: "",
    };

    setRequestErrors({
      ...requestErrors,
      stops: stopsErrors,
    });
  };

  const handleAdditionalStopInfoChange = (
    additionalStopInfo: Partial<Stop>
  ) => {
    onStopChange({
      ...additionalStopInfo,
      // if dateTime is changed
      ...(additionalStopInfo.dateTime !== stop.dateTime && {
        dateTime: moment(additionalStopInfo.dateTime).toISOString(),
        // for first flight, if dateTime changes, flight info should also reset
        ...(stop.stopIndex === 1 && {
          flightNumber: null,
          trackedFlight: null,
        }),
      }),
    });
  };

  // blurs input focus when clicking drag handle
  // dnd library prevents default on handle events
  const handleBlur = () => {
    addressRef?.current?.blur();
    airportRef?.current?.blur();
  };

  /* dateTime, airport, ailine and flight number are coupled for
  searching a flight. handleAirportDetailsBlur and
  handleAirportDetailsKeyDown to make sure handleStopFlightSearch
  is triggered when flight number is entered and dateTime/airport
  or airline are entered later*/
  const handleAirlineDetailsBlur = () => {
    if (stop.variant === "airport" && !!flightNumberInput) {
      handleStopFlightSearch();
    }
  };

  const handleAirlineDetailsKeyDown = (
    event: KeyboardEvent<HTMLDivElement>
  ) => {
    if (
      stop.variant === "airport" &&
      !!flightNumberInput &&
      (event.keyCode === 13 || event.key === "Enter")
    ) {
      handleStopFlightSearch();
    }
  };

  // time manually adjusted to display properly on DateTimePicker
  const adjustedStopDateTime = stop.dateTime
    ? applyUTCOffsetToTime(stop.dateTime, "add")
    : null;

  const shouldShowAdditionalStopInfoButton =
    stop.stopIndex === 1 && !canOnlyUpdateStopDate;

  return (
    <>
      <div>
        <Box display="flex" flexDirection="column">
          {/* first stop date & time */}
          {stop.stopIndex === 1 && (
            <Box mb={4}>
              <Box mb={1.5}>
                <Typography variant="h3">Date & Time</Typography>
              </Box>
              <Box mb={2}>
                <Divider />
              </Box>
              <MoovsDateTimePickerWithConfirm
                clearable={stop.stopIndex !== 1}
                label={`${stopLabel} Date & Time`}
                value={adjustedStopDateTime}
                onAccept={handleFirstStopDateTimeChange}
                inputFormat={dateFormatter(null, "abbreviated", {
                  mode: "dateTime",
                  returnFormatString: true,
                })}
                renderInputProps={{
                  name: "pickupDateTime",
                  onBlur: handleAirlineDetailsBlur,
                  onKeyDown: handleAirlineDetailsKeyDown,
                  error: stopErrors && !!stopErrors.dateTime,
                  helperText: stopErrors && stopErrors.dateTime,
                }}
              />
            </Box>
          )}

          {/* stop header */}
          <Box
            display="flex"
            mb={1}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection={isMobileView ? "column" : "row"}
              alignItems={isMobileView ? "flex-start" : "center"}
            >
              <Box mr={2} mb={1}>
                <Typography variant="h3">{stopLabel}</Typography>
              </Box>
              <AddressAirportToggle
                onChange={handleStopTypeToggle}
                value={nextStop?.variant || stop.variant}
              />
            </Box>

            <Box display="flex" alignItems="center">
              {showIncorrectStopOrderWarning && (
                <MoovsTooltip tooltipText="Date & time falls before a previous date & time">
                  <IconButton size="large">
                    <WarningIcon color={orange} />
                  </IconButton>
                </MoovsTooltip>
              )}
              {shouldShowAdditionalStopInfoButton && (
                <AdditionalStopInfoButtonPanel
                  stopLabel={stopLabel}
                  dateTime={stop.stopIndex !== 1 && stop.dateTime}
                  groupSize={stop.groupSize}
                  note={stop.note}
                  onEditAdditionalStopInfoClick={() =>
                    setAdditionalStopInfoOpen(true)
                  }
                />
              )}

              {/* edit additional info / remove stop buttons */}
              {stop.stopIndex !== 1 && (
                <EditRemoveStopButtons
                  canRemoveStop={canRemoveStop}
                  showAdditionalStopInfoButton={stop.stopIndex !== stopsLength}
                  onRemoveStopClick={onRemoveStop}
                  onEditAdditionalStopInfoClick={() =>
                    setAdditionalStopInfoOpen(true)
                  }
                  stopLabel={stopLabel}
                />
              )}
            </Box>
          </Box>
          <Box mb={1}>
            <Divider />
          </Box>
          <Droppable droppableId={stop.id} direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{
                  backgroundColor: snapshot.isDraggingOver ? alabaster : white,
                  borderRadius: "4px",
                  height: "72px",
                  // prevents background from going on top of dragging item
                  zIndex: snapshot.isDraggingOver ? 900 : 1000,
                }}
                {...provided.droppableProps}
              >
                {/* temp draggable value */}
                {/* used to visually rearrange stops while dragging */}
                {nextStop && !isDestinationIndex && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    flex="1"
                    alignItems="center"
                    bgcolor={white}
                    pl={2}
                    pr={0}
                    py={1}
                    mb={2}
                    borderRadius="4px"
                  >
                    <Box
                      mr={1.5}
                      height={56}
                      display="flex"
                      alignItems="center"
                    >
                      <RearrangeIcon />
                    </Box>
                    <Box display="flex" flex="1" width="100%">
                      <TextField
                        required
                        fullWidth
                        variant="outlined"
                        label={nextStopLabel}
                        value={nextStopValue}
                      />
                    </Box>
                  </Box>
                )}
                <Draggable index={stop.stopIndex - 1} draggableId={stop.id}>
                  {(provided, draggableSnapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      {/* address/airport reorderable input */}
                      <Box
                        display={nextStop && !isSourceIndex ? "none" : "flex"}
                        flexDirection="row"
                        flex="1"
                        alignItems="center"
                        bgcolor={white}
                        boxShadow={
                          draggableSnapshot.isDragging
                            ? "0px 0px 30px rgba(0, 0, 0, 0.08)"
                            : "none"
                        }
                        py={1}
                        mb={2}
                        pl={2}
                        pr={draggableSnapshot.isDragging ? 2 : 0}
                        borderRadius="4px"
                      >
                        <Box
                          mr={1.5}
                          height={56}
                          display="flex"
                          alignItems="center"
                          {...provided.dragHandleProps}
                          onMouseDown={handleBlur}
                        >
                          <RearrangeIcon />
                        </Box>
                        <Box display="flex" flex="1" width="100%">
                          {/* address */}
                          <Box
                            display={
                              stop.variant === "address" ? "flex" : "none"
                            }
                            flex="1"
                          >
                            <LocationAutoComplete
                              inputRef={addressRef}
                              required
                              label="Address"
                              fullWidth
                              value={stop.location}
                              onChange={handleStopLocationChange}
                              error={stopErrors && !!stopErrors.location}
                              helperText={stopErrors && stopErrors.location}
                            />
                          </Box>
                          {/* airport */}
                          <Box
                            display={
                              stop.variant === "airport" ? "flex" : "none"
                            }
                            flex="1"
                          >
                            <AirportAutoComplete
                              inputRef={airportRef}
                              fullWidth
                              required
                              label={getAirportLabel(stop.stopIndex)}
                              onChange={(airport: Airport) => {
                                handleStopChange("airport", airport);
                              }}
                              onBlur={handleAirlineDetailsBlur}
                              onKeyDown={handleAirlineDetailsKeyDown}
                              value={stop.airport}
                              error={stopErrors && !!stopErrors.airport}
                              helperText={stopErrors && stopErrors.airport}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  )}
                </Draggable>
                <Box visibility="hidden" height={0}>
                  {provided.placeholder}
                </Box>
              </div>
            )}
          </Droppable>
        </Box>

        {stop.variant === "airport" && (
          <Box
            p={2}
            mb={2}
            style={{ backgroundColor: alabaster, borderRadius: "4px" }}
          >
            {/* airport stop type additional info fields */}
            <>
              {/* airline */}
              <Box flex="1" mb={1}>
                <AirlineAutoComplete
                  fullWidth
                  label="Airline"
                  onChange={(airline: Airline) => {
                    handleStopChange("airline", airline);
                  }}
                  onBlur={handleAirlineDetailsBlur}
                  onKeyDown={handleAirlineDetailsKeyDown}
                  value={stop.airline}
                  error={stopErrors && !!stopErrors.airline}
                  helperText={stopErrors && stopErrors.airline}
                />
              </Box>

              {/* flight number */}
              <Box flex="1" mb={1} display="flex" alignItems="center">
                <FlightNumberInput
                  label="Flight"
                  onFlightNumberInputChange={handleStopFlightNumberChange}
                  onFlightSearch={handleStopFlightSearch}
                  isLoading={potentialTrackedFlightLoading}
                  airlineIata={stop?.airline?.iataCode}
                  error={stopErrors && !!stopErrors.flightNumber}
                  hasFlight={!!stop?.trackedFlight}
                  onClearFlight={handleClearTrackedFlight}
                  value={flightNumberInput}
                />
              </Box>

              {stop.trackedFlight && !potentialTrackedFlightLoading && (
                <Box>
                  <FlightInfoCard trackedFlight={stop.trackedFlight} />
                </Box>
              )}

              {potentialTrackedFlightLoading && (
                <Box
                  py={10}
                  style={{ backgroundColor: grayLight, borderRadius: "4px" }}
                  display="flex"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              )}
            </>
          </Box>
        )}
      </div>

      <AdditionalStopInfoDialog
        clearable={stop.stopIndex !== 1}
        stopLabel={stopLabel}
        open={additionalStopInfoOpen}
        onClose={() => setAdditionalStopInfoOpen(false)}
        stop={stop}
        canOnlyUpdateStopDate={canOnlyUpdateStopDate}
        onSave={handleAdditionalStopInfoChange}
      />

      {!!multipleFlightsDialogData && (
        <MultipleFlightsDialog
          open={!!multipleFlightsDialogData}
          onClose={() => setMultipleFlightsDialogData(undefined)}
          onAccept={handleMultipleFlightSelect}
          data={multipleFlightsDialogData}
        />
      )}
    </>
  );
}

export default StopsBlockItem;
