import React, { useMemo } from "react";

import Grid from "@mui/material/Grid"; // Grid version 2

import { VehicleChildSeat, VehicleFeature } from "types";
import {
  ChildSeatFeatures,
  GeneralFeatures,
  MultimediaFeatures,
  PolicyFeatures,
} from "./components";
import { grayDark } from "design-system/colors";

type VehicleFeaturesProps = {
  features: VehicleFeature[];
  hasTripConflict: boolean;
  childSeats: VehicleChildSeat[];
};

function VehicleFeatures(props: VehicleFeaturesProps) {
  const { features = [], hasTripConflict, childSeats } = props;

  // memo
  const vehicleFeaturesByCategory = useMemo(() => {
    return {
      general: features.filter(({ category }) => category === "general"),
      multimedia: features.filter(({ category }) => category === "multimedia"),
      policy: features.filter(({ category }) => category === "policies"),
    };
  }, [features]);

  // derived state
  const hasChildSeats = childSeats.length > 0;

  return (
    <Grid container direction="row" spacing={2}>
      {/* general */}
      <Grid
        sm={12}
        md={6}
        lg={hasChildSeats ? 3.75 : 4.5}
        width="100%"
        color={grayDark}
      >
        <GeneralFeatures
          features={vehicleFeaturesByCategory.general}
          hasTripConflict={hasTripConflict}
        />
      </Grid>

      {/* multimedia */}
      <Grid sm={12} md={6} lg={hasChildSeats ? 3.1 : 4.5} width="100%">
        <MultimediaFeatures
          features={vehicleFeaturesByCategory.multimedia}
          hasTripConflict={hasTripConflict}
        />
      </Grid>

      {/* policy */}
      <Grid sm={12} md={6} lg={hasChildSeats ? 1.95 : 3} width="100%">
        <PolicyFeatures
          features={vehicleFeaturesByCategory.policy}
          hasTripConflict={hasTripConflict}
        />
      </Grid>

      {/* child seats */}
      {hasChildSeats ? (
        <Grid sm={12} md={6} lg={3.2} width="100%">
          <ChildSeatFeatures
            childSeats={childSeats}
            hasTripConflict={hasTripConflict}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}

export default VehicleFeatures;
