import { RequestStage } from "types";
import uniqueId from "lodash/uniqueId";

import {
  CreateContactState,
  CreateRequestState,
  CreateStopState,
  CreateTripState,
} from "./types";

const getInitialContact = (): CreateContactState => ({
  firstName: "",
  lastName: "",
  email: "",
  mobilePhone: "",
  phoneCountryName: "",
  phoneCountryFormat: "",
  phoneCountryCode: "",
  phoneCountryDialCode: "",
});

export const getInitialTrip = (): CreateTripState => ({
  id: uniqueId(),
  note: "",
  totalGroupSize: null,
  tripCategory: null,
  useTotalDuration: false,
  totalDuration: null,
  estimatedDuration: null,
  googleDirectionsResult: null,
  stops: [getInitialStop(1), getInitialStop(2)],
  temporaryPassenger: null,
  routes: [
    {
      id: uniqueId(),
      vehicle: null,
      carryOnLuggage: 0,
      checkedLuggage: 0,
      oversizeLuggage: 0,
    },
  ],
  selectedPassenger: null,
  statusUpdateTextTo: null,
});

export const getInitialStop = (
  stopIndex: number,
  stopId?: string
): CreateStopState => ({
  stopIndex,
  id: !!stopId ? stopId : uniqueId("stop"),
  variant: "address",
  location: "",
  dateTime: null,
  trackedFlight: null,
  airport: null,
  airline: null,
  flightNumber: null,
  groupSize: null,
  note: "",
  pickUpGooglePlaceTypes: null,
  skipFlightDetails: false,
  isLocationAirport: false,
});

export const initialRequest: CreateRequestState = {
  orderType: null,
  returnTrip: null,
  stage: RequestStage.Quote,
  trip: getInitialTrip(),
  bookingContact: getInitialContact(),
};
