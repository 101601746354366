import React from "react";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";

import { Box, Divider, Typography } from "@mui/material";

import RHFInternationalPhoneInput from "components/react-hook-form/RHFInternationalPhoneInput";
import PassengerCountBlock from "pages/new/steps/info/components/PassengerCountBlock";
import { RHFTextField } from "components/react-hook-form/text-field/RHFTextField";
import { AdditionalTripInfoState } from "../form/schemaValidation";
import { useCurrentUser, useLaunchDarklyFlags } from "globals/hooks";
import LuggageCountBlock from "./LuggageCountBlock";
import PassengerDetailsCard from "./PassengerDetailsCard/PassengerDetailsCard";

type ATIFormFieldsProps = {
  control: Control<AdditionalTripInfoState>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
};

function ATIFormFields(props: ATIFormFieldsProps) {
  const { control, setValue, watch } = props;

  // hooks
  const { enableLinkedPassenger } = useLaunchDarklyFlags();
  const currentUser = useCurrentUser();

  return (
    <>
      {/* Passenger Count */}
      <Box mt={2}>
        <PassengerCountBlock name="totalGroupSize" control={control} />
        <Divider sx={{ my: 3 }} />
      </Box>

      {enableLinkedPassenger && currentUser ? (
        <>
          {/* Passenger Details */}
          <Typography sx={{ mb: 1.5 }} variant="h4">
            Passenger Details
          </Typography>
          <Box>
            <PassengerDetailsCard
              setValue={setValue}
              control={control}
              watch={watch}
            />
          </Box>
          <Divider />
        </>
      ) : (
        <>
          {/* Temporary Passenger */}
          <Box mb={3}>
            <Typography sx={{ mb: 1.5 }} variant="h4">
              Passenger Contact
            </Typography>
            <Box mb={1}>
              <RHFTextField
                fullWidth
                variant="outlined"
                label="Name"
                name="temporaryPassenger.name"
                control={control}
              />
            </Box>
            <RHFInternationalPhoneInput
              name="temporaryPassenger.phoneFields"
              label="Mobile Phone"
              control={control}
            />
          </Box>
        </>
      )}

      {/* Luggage Count */}
      <>
        <Box mb={4}>
          <LuggageCountBlock
            control={control}
            watch={watch}
            setValue={setValue}
            syncStateWithOutboundTrip={false}
            forceDisplay={true}
          />
        </Box>
        <Divider />
      </>
      {/* Trip Notes */}
      <Box my={3}>
        <Typography variant="h4" sx={{ mb: 1.5 }}>
          Trip Notes
        </Typography>
        <RHFTextField
          required
          multiline
          fullWidth
          rows={3}
          control={control}
          variant="outlined"
          placeholder="Add any additional notes you would like for us to know"
          name="note"
        />
      </Box>
    </>
  );
}

export default ATIFormFields;
