import React from "react";
import { FetchMoreQueryOptions } from "@apollo/client";
import { ApolloQueryResult } from "@apollo/client";

import { useScreenSize } from "globals/hooks";
import { Trip } from "types";
import ContactTripsGrid from "./ContactTripsGrid";



type TripsListProps = {
  trips: Trip[];
  tripsLoading: boolean;
  fetchMore: (options: FetchMoreQueryOptions<any, any>) => Promise<ApolloQueryResult<any>>;
  hasNextPage: boolean;
  cursor: string;
  refetch: () => void;
  refetchPastTrips: () => void;
};

function TripsList(props: TripsListProps) {
  const { trips, tripsLoading, fetchMore, hasNextPage, cursor, refetch, refetchPastTrips } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <ContactTripsGrid
      trips={trips}
      tripsLoading={tripsLoading}
      fetchMore={fetchMore}
      hasNextPage={hasNextPage}
      cursor={cursor}
      refetch={refetch}
      refetchPastTrips={refetchPastTrips}
      {...(isMobileView
        ? { sx: { mx: -2, height: Math.min(450, Math.max(300, trips.length * 50)) } }
        : {
          sx: {
            mx: 0,
            height: Math.min(450, Math.max(250, trips.length * 50)),
            "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
            {
              display: "none",
            },
          },
        })}
    />
  );
}

export default TripsList;
