/*
  hook: useFlightTracking
  description: this hook uses the loadPotentialTrackedFlight and updates the RHF form
    for related flight info
*/

import { KeyboardEvent, useState } from "react";
import { useFormContext } from "react-hook-form";

import { useLazyQuery } from "@apollo/client";

import { black, errorRed, white } from "design-system/colors";
import { LOAD_POTENTIAL_TRACKED_FLIGHT_QUERY } from "globals/graphql";
import { useSnackbar } from "globals/hooks";
import { TrackedFlight } from "types";
import { parseNumberFromFlightNumber } from "utils/helpers";

type UseFlightTrackingProps = {
  formNameTripStops: string;
  stopsArrayIndex: number;
  isAirport: boolean;
  isDropOff: boolean;
};

function useFlightTracking(args: UseFlightTrackingProps) {
  const { formNameTripStops, stopsArrayIndex, isDropOff } = args;
  const formNameTripStop = `${formNameTripStops}.${stopsArrayIndex}`;
  const formNameTripFirstStop = `${formNameTripStops}.0`;

  // state
  const [isSearchingForFlight, setIsSearchingForFlight] = useState(false);
  const [multipleFlightsDialogData, setMultipleFlightsDialogData] = useState<{
    flights: TrackedFlight[];
    flightNumber: string;
    inputAirportIcao: string;
  }>(undefined);

  // hooks
  const snackbar = useSnackbar();

  // form names
  const formNameFlightNumber = `${formNameTripStop}.flightNumber`;
  const formNameAirportInput = `${formNameTripStop}.airport`;
  const formNameAirlineInput = `${formNameTripStop}.airline`;
  const formNameFirstStopDateTime = `${formNameTripFirstStop}.dateTime`;
  // form hooks
  const { watch, trigger, setError, setValue } = useFormContext();
  const [stop, firstStop] = watch([
    `${formNameTripStop}`,
    `${formNameTripStops}.0`,
  ]);

  // queries
  const [
    loadPotentialTrackedFlight,
    { loading: potentialTrackedFlightLoading },
  ] = useLazyQuery(LOAD_POTENTIAL_TRACKED_FLIGHT_QUERY, {
    onCompleted: ({ loadPotentialTrackedFlight: trackedFlightData }) => {
      setIsSearchingForFlight(false);

      const faFlightNumber = `${stop.airline.icaoCode}${Number(
        stop.flightNumber
      )}`;

      // determine effects based on # of matching flights
      if (trackedFlightData.length === 0) {
        // no flights
        setError(formNameFlightNumber, {
          type: "no flight found",
          message:
            "Flight does not exist. Please check the number and try again",
        });

        snackbar.warning(
          "Flight does not exist. Please check the number and try again",
          { snackbarColor: white, iconColor: errorRed, textColor: black }
        );
      } else if (trackedFlightData.length === 1) {
        // exactly one flight match
        const [trackedFlight] = trackedFlightData;
        // remove the airline from the flight number to save flight number input value correctly
        const numericFlightNumber = (
          trackedFlight.actualFlightNumber || trackedFlight.flightNumber
        ).replace(/\D/g, "");

        setValue(formNameTripStop, {
          ...stop,
          trackedFlight,
          flightNumber: numericFlightNumber,
        });
      } else {
        // multiple flights match
        setMultipleFlightsDialogData({
          flightNumber: faFlightNumber,
          flights: trackedFlightData,
          inputAirportIcao: stop.airport?.icaoCode,
        });
      }
    },
    onError: () => {
      setIsSearchingForFlight(false);
      snackbar.error("Could not retrieve flight");
    },
    fetchPolicy: "network-only",
  });

  // event handlers
  const handleStopFlightSearch = (calledFrom: string) => async () => {
    const { airline, airport, flightNumber } = stop;
    const firstStopDateTime = firstStop.dateTime;

    const shouldSearchForFlights =
      calledFrom === formNameFlightNumber ||
      (airline && airport && flightNumber && firstStopDateTime);

    if (!shouldSearchForFlights) return;

    const hasNoMissingInformation = await trigger([
      formNameAirlineInput,
      formNameAirportInput,
      formNameFlightNumber,
      formNameFirstStopDateTime,
    ]);

    if (!hasNoMissingInformation) {
      snackbar.warning("Please enter missing information to search flights", {
        snackbarColor: white,
        iconColor: errorRed,
        textColor: black,
      });
      return;
    }

    setIsSearchingForFlight(true);
    loadPotentialTrackedFlight({
      variables: {
        firstStopDateTime,
        flightNumber: parseFloat(flightNumber),
        airline: stop.airline?.icaoCode,
        ...{
          ...(isDropOff
            ? { departureAirport: stop.airport?.icaoCode }
            : { arrivalAirport: stop.airport?.icaoCode }),
        },
      },
    });
  };

  const handleSearchFlightsOnEnterKeyPress = (
    event: KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.keyCode === 13 || event.key === "Enter") {
      handleStopFlightSearch(formNameAirportInput);
    }
  };
  const handleMultipleFlightSelect = (selectedFlight: TrackedFlight) => {
    setValue(formNameTripStop, {
      ...stop,
      trackedFlight: selectedFlight,
      flightNumber: parseNumberFromFlightNumber(selectedFlight.flightNumber),
    });
    setMultipleFlightsDialogData(undefined);
  };

  const handleMultipleFlightsDialogClose = () => {
    setMultipleFlightsDialogData(undefined);
  };

  return {
    isSearchingForFlight,
    trackedFlightLoading: potentialTrackedFlightLoading,
    multipleFlightsDialogData,
    handleMultipleFlightsDialogClose,
    handleStopFlightSearch,
    handleSearchFlightsOnEnterKeyPress,
    handleMultipleFlightSelect,
  };
}

export { useFlightTracking };
