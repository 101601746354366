import React from "react";
import last from "lodash/last";

import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Stop } from "types";


function DropoffLocationColumn(props: GridRenderCellParams<string>) {
  const { row } = props;

  const dropoffLocation = last(row.stops as unknown as Stop[]).location;

  return <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">{dropoffLocation}</Typography>;
}

export default DropoffLocationColumn;
