import React from "react";

import { Box, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Trip } from "types";
import { orderTypeEnumToNameMap } from "utils/enumMaps";


function OrderTypeColumn(props: GridRenderCellParams<Trip>) {
    const { row } = props;

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            width="100%"
        >
            <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">
                {orderTypeEnumToNameMap[row.request.orderType]}
            </Typography>
        </Box>
    );
}

export default OrderTypeColumn;
